import { useEffect, useRef, useState } from "react";
import { IconButton, useToast } from "@chakra-ui/react";
import { RiImageAddLine } from "react-icons/ri";
import { useReactiveVar } from "@apollo/client";
import { attachmentStateVar } from "src/store/messaging";

export default function ImageAttachment() {
  const [fileName, setFileName] = useState("");
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();
  const attachmentState = useReactiveVar(attachmentStateVar);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    if (file) {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", file);

      const authToken = JSON.parse(localStorage.getItem("auth")!).token;

      if (!authToken) {
        toast({
          title: "Error occurred",
          description: "Please try again",
          status: "error",
        });
      }

      fetch(`${process.env.REACT_APP_API_URL}/api/v1/s3`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error((await response.json()).message);
          }
          return response.json();
        })
        .then((data) => {
          setFileName(file.name);
          setUploading(false);
          attachmentStateVar({ key: data.key, type: file.type.split("/")[0] });
        })
        .catch((error) => {
          console.error("Error:", error);
          toast({
            title: "Error occured",
            description: error.message,
            status: "error",
          });

          setUploading(false);
          attachmentStateVar(null);
        });
    }
  };

  useEffect(() => {
    if (attachmentState === null) {
      setFileName("");
    }
  }, [attachmentState]);

  return (
    <div className="flex rounded-lg items-center">
      <IconButton
        variant="ghost"
        aria-label="Attach image"
        onClick={() => fileInputRef.current?.click()}
        icon={
          <div className="flex items-center gap-2 mx-2">
            <RiImageAddLine />
            <p className="text-sm">
              {uploading
                ? "Uploading..."
                : fileName
                ? fileName
                : "Attach image"}
            </p>
          </div>
        }
      />
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        onChange={handleFileChange}
        accept="image/webp, image/png, image/jpeg, image/jpg, image/gif"
      />
    </div>
  );
}
