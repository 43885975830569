import { useApolloClient } from "@apollo/client";
import { Button } from "@chakra-ui/react";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useAuth } from "src/hooks/useAuth";

export default function LogoutButton() {
  const { logout } = useAuth();
  const client = useApolloClient();
  function handleLogout() {
    client.resetStore();
    logout();
  }
  return (
    <Button
      onClick={() => handleLogout()}
      variant="outline"
      size="sm"
      w="full"
      leftIcon={<ArrowRightOnRectangleIcon className="w-4 h-4" />}
    >
      Logout
    </Button>
  );
}
