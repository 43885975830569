import { Button, ButtonGroup, IconButton } from "@chakra-ui/react";

import { RiCloseLine } from "react-icons/ri";
import { filesVar } from "../../../../../store/messaging";
import { useReactiveVar } from "@apollo/client";

export default function Attachments() {
  /*   const files = useReactiveVar(filesVar);
  const storage = getStorage();
  function openLink(file: string) {
    window.open(file, "_blank");
  } */

  /*   function handleDelete(file: string) {
    const removedFiles = files.filter((f) => {
      return file !== f.url;
    });
    filesVar(removedFiles);
    const desertRef = ref(storage, file);
    deleteObject(desertRef)
      .then(() => {
        console.log("file delete");
      })
      .catch((error) => {
        console.log(error);
      });
  } */
  /*   if (!!files.length)
    return (
      <div className="p-1 flex place-items-center mb-1 space-x-2">
        {files.map((file) => {
          return (
            <ButtonGroup key={file.url} isAttached variant="outline" size="sm">
              <Button onClick={() => openLink(file.url)}>
                {
                  file.name
                    .replace(
                      "https://firebasestorage.googleapis.com/v0/b/smartyr-inbox.appspot.com/o/files",
                      ""
                    )
                    .split("?alt=media&token")[0]
                }
              </Button>
              <IconButton
                onClick={() => handleDelete(file.url)}
                aria-label={"Remove Attachment"}
                icon={<RiCloseLine />}
              />
            </ButtonGroup>
          );
        })}
      </div>
    ); */
  return null;
}
