import { Contact } from "../types/Contact";
import { makeVar } from "@apollo/client";

//Message Page Variables

type InputStates = {
  SMSBody?: string;
  reviewReply?: string;
  emailSubject?: string;
  emailBody?: string;
  facebookChat?: string;
  facebookRecommendation?: string;
  feedback?: string;
  selectedChannel?: string;
  inputFocus?: boolean;
};

type ContactStates = {
  [key: string]: InputStates;
};

type File = {
  name: string;
  url: string;
  base64: any;
};

export const filesVar = makeVar<File[]>([]);

export const inputStatesVar = makeVar<InputStates>({
  SMSBody: undefined,
  reviewReply: undefined,
  emailSubject: undefined,
  emailBody: undefined,
  facebookChat: undefined,
  facebookRecommendation: undefined,
  feedback: undefined,
  selectedChannel: undefined,
  inputFocus: false,
});

export const attachmentStateVar = makeVar<{
  key: string;
  type: string;
} | null>(null);

export const mediaUrlsVar = makeVar<string[]>([]);

export const contactStatesVar = makeVar<ContactStates>({});

export const reviewDataVar = makeVar<any>({
  contact_google_url: "",
  message_id: "",
});
export const isEmailActionsVisibleVar = makeVar<boolean>(false);

export const isMessageSendingVar = makeVar<boolean>(false);
