import {
  Avatar,
  Button,
  Center,
  CircularProgress,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { DELETE_USER, GET_USERS } from "../../../apollo/users";
import { useMutation, useQuery } from "@apollo/client";

import { GET_USER_DETAILS } from "../../../apollo/user";
import { ROLES } from "../../../constants/ROLES";
import { TrashIcon } from "@heroicons/react/24/outline";
import { UserEdge } from "../../../types/User";

export default function UsersTable() {
  const toast = useToast();
  const { loading, data } = useQuery(GET_USERS, {
    onError(error) {
      console.log(error);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
  });

  const { data: userData } = useQuery(GET_USER_DETAILS);
  function handleDelete(id: string) {
    deleteUser({
      variables: {
        deleteUserId: id,
      },
    });
  }

  const [deleteUser, { loading: deleteLoading }] = useMutation(DELETE_USER, {
    onError: (error) => {
      console.log(error);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
    onCompleted: () => {
      toast({
        title: "User deleted",
        status: "success",
      });
    },
    refetchQueries: [{ query: GET_USERS }, "GetUsers"],
  });

  if (loading)
    return (
      <Center h="100%">
        <CircularProgress isIndeterminate color="blue.400" />
      </Center>
    );
  if (data)
    return (
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Role</Th>

              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.users.edges.map((user: UserEdge) => (
              <Tr key={user.node.id}>
                <Td>
                  <Text>{user.node.name}</Text>
                </Td>
                <Td>
                  <Text fontSize="sm" mt={0.5}>
                    {user.node.email}
                  </Text>
                </Td>
                <Td>{ROLES[user.node.role as keyof typeof ROLES]}</Td>
                <Td>
                  {userData && user.node.id !== userData.me.id && (
                    <Popover placement="right">
                      <PopoverTrigger>
                        <IconButton aria-label="Remove" variant="ghost">
                          <TrashIcon className="w-4 h-4" />
                        </IconButton>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>Confirmation!</PopoverHeader>
                          <PopoverBody>
                            Are you sure you wish to delete this user?
                          </PopoverBody>
                          <PopoverFooter>
                            <Button
                              colorScheme="red"
                              isLoading={deleteLoading}
                              onClick={() => handleDelete(user.node.id)}
                            >
                              Delete
                            </Button>
                          </PopoverFooter>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  return null;
}
