import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GetUsers {
    users {
      edges {
        cursor
        node {
          createdAt
          email
          id
          name
          updatedAt
          emailVerified
          role
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        nodes
        startCursor
        hasPreviousPage
      }
    }
  }
`;

export const INVITE_USER = gql`
  mutation Mutation($email: String) {
    sendInvite(email: $email) {
      accepted
      createdAt
      email
      expiredAt
      id
      updatedAt
    }
  }
`;

export const GET_INVITES = gql`
  query GetInvites($first: Int) {
    invites(first: $first) {
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          email
          accepted
          expiredAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        nodes
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation Mutation($deleteUserId: ID!) {
    deleteUser(id: $deleteUserId) {
      name
    }
  }
`;
