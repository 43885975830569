import * as yup from "yup";

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { RiArrowLeftLine, RiMailLine } from "react-icons/ri";

import AuthLayout from "./AuthLayout";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
});

type FormData = {
  email: string;
};

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const toast = useToast();

  function onSubmit(data: FormData) {
    setIsLoading(true);
    /*     sendPasswordResetEmail(auth, data.email)
      .then(() => {
        reset();
        setIsLoading(false);
        toast({
          title: "Password reset sent",
          status: "success",
        });
        navigate("/login");
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-email":
            setError("email", {
              type: "custom",
              message: "Account does not exist",
            });
            break;
          case "auth/user-not-found":
            setError("email", {
              type: "custom",
              message: "User not found",
            });
            break;
          default:
            toast({
              title: "Error occurred",
              description: error.message,
              status: "error",
            });
        }
        setIsLoading(false);
      }); */
  }

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  return (
    <AuthLayout
      title="Reset password"
      description="Check email with instructions to reset"
    >
      <div>
        <Button
          variant="ghost"
          size="sm"
          leftIcon={<RiArrowLeftLine />}
          onClick={() => navigate("/login")}
          aria-label="Navigate to login"
        >
          Go Back
        </Button>

        <form
          className="space-y-2 mt-2 flex flex-col"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <RiMailLine />
              </InputLeftElement>
              <Input
                placeholder="i.e johnsmith@gmail.com..."
                type="email"
                autoComplete="email"
                id="email"
                {...register("email")}
              />
            </InputGroup>
            {!!errors.email && (
              <FormErrorMessage>{errors.email.message}</FormErrorMessage>
            )}
          </FormControl>
          <Button
            type="submit"
            variant="solid"
            colorScheme="blue"
            isLoading={isLoading}
          >
            Submit
          </Button>
        </form>
      </div>
    </AuthLayout>
  );
}
