import { Navigate, Route, Routes } from "react-router-dom";

import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
import ContactView from "../features/contacts/contact_view/ContactView";
import ContactsPage from "../features/contacts/index";
import EmbedView from "../features/settings/embed/EmbedView";
import GroupMessageView from "../features/inbox/message_view/GroupMessageView";
import InboxPage from "../features/inbox/index";
import LoginPage from "../features/auth/Login";
import NewMessageView from "../features/inbox/inbox_view/NewMessageView";
import OrganisationView from "../features/settings/organisation/OrganisationView";
import ResetPassword from "../features/auth/ResetPassword";
import SettingsPage from "../features/settings";
import SignUpPage from "../features/auth/SignUp";
import SingleMessageView from "../features/inbox/message_view/MessageView";
import TemplatesView from "../features/settings/templates/TemplatesView";
import UserView from "../features/settings/user/UserView";
import UsersView from "../features/settings/users/UsersView";
import ApplicationView from "src/features/settings/application/ApplicationView";
import IntegrationsView from "src/features/settings/integrations/IntegrationsView";

export default function RootNavigator() {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="sign-up" element={<SignUpPage />} />
      <Route path="reset-password" element={<ResetPassword />} />

      <Route element={<AuthenticatedLayout />}>
        <Route path="inbox" element={<InboxPage />}>
          <Route path="new-message" element={<NewMessageView />} />
          <Route path="group-message" element={<GroupMessageView />} />
          <Route path=":inboxId" element={<SingleMessageView />} />
        </Route>
        <Route path="contacts" element={<ContactsPage />}>
          <Route path=":contactId" element={<ContactView />} />
        </Route>
        <Route path="settings" element={<SettingsPage />}>
          <Route path="user" element={<UserView />} />
          <Route path="organisation" element={<OrganisationView />} />
          <Route path="users" element={<UsersView />} />
          <Route path="review-embed" element={<EmbedView />} />
          <Route path="templates" element={<TemplatesView />} />
          <Route path="application" element={<ApplicationView />} />
          <Route path="integrations" element={<IntegrationsView />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={"/inbox"} />} />
    </Routes>
  );
}
