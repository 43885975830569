import { Flex, Heading } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import BackButton from "../../components/BackButton";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

type Props = {
  name: string;
  actions?: ReactNode;
  children: ReactNode;
  description?: string;
};

export default function Layout({
  name,
  actions,
  children,
  description,
}: Props) {
  const navigate = useNavigate();
  return (
    <div className=" z-10 w-full  bg-white rounded-lg shadow-sm border-gray-200 border md:p-6">
      <div className="flex gap-2 place-items-center  border-b px-3 md:pt-0 pt-3 pb-3 justify-between">
        <div className="flex gap-1 place-items-center">
          <BackButton hideDesktop={true} />
          <div className="space-y-0.5">
            <h3 className="text-lg font-bold">{name}</h3>
            <p className="hidden md:block text-sm text-gray-800">
              {description}
            </p>
          </div>
        </div>
        {actions}
      </div>
      <div className="p-3">{children}</div>
    </div>
  );
}
