import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

interface AuthData {
  token: string | null;
  refreshToken: string | null;
}

interface AuthContextType extends AuthData {
  login: (data: AuthData) => Promise<void>;
  logout: () => void;
  isTokenExpired: () => Promise<boolean>;
  refreshCurrentToken: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [auth, setAuth] = useLocalStorage("auth", {
    token: null,
    refreshToken: null,
  });
  const navigate = useNavigate();

  const login = async (data: AuthData) => {
    setAuth(data);
    navigate("/inbox", { replace: true });
  };

  const logout = () => {
    setAuth({ token: null, refreshToken: null });
    navigate("/login", { replace: true });
  };

  const isTokenExpired = async () => {
    if (auth.token) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/auth/check-token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: auth.token }),
          }
        );

        return response.status !== 200;
      } catch (error) {
        console.error("Error checking token validity:", error);
        return true;
      }
    }
    return true;
  };

  const refreshCurrentToken = async () => {
    if (auth.refreshToken) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/auth/refresh-token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refreshToken: auth.refreshToken }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          setAuth({
            token: data.token,
            refreshToken: data.refreshToken,
          });
        } else {
          throw new Error("Failed to refresh token");
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
        logout();
      }
    }
  };

  const value = useMemo(
    () => ({
      ...auth,
      login,
      logout,
      isTokenExpired,
      refreshCurrentToken,
    }),
    [auth]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
