import { gql } from "@apollo/client";

export const CREATE_CONTACT = gql`
  mutation CreateContact($contact: ContactInputType!) {
    createContact(contact: $contact) {
      name
      email
      phone
      id
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation DeleteContact($deleteContactId: ID!) {
    deleteContact(id: $deleteContactId) {
      id
    }
  }
`;
export const GET_CONTACT_DETAILS = gql`
  query GetContactDetails($contactId: ID!) {
    contact(id: $contactId) {
      id
      name
      email
      phone
      googleAvatar
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($updateContactId: ID!, $contact: ContactInputType!) {
    updateContact(id: $updateContactId, contact: $contact) {
      createdAt
      email
      googleAvatar
      id
      lastActivityAt
      name
      open
      phone
      updatedAt
    }
  }
`;
