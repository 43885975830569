import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { KEYWORDS } from "../../../constants/KEYWORDS";
import { Keyword } from "../../../types/Keyword";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { keywordsStateVar } from "../../../store/templates";

type Props = {
  keywords: Keyword[];

  setValue: any;
  body: string;
};
type KeywordArray = KeywordItem[] | [];
type KeywordItem = {
  id: string;
  key: string;
};
export default function SelectKeyword({ keywords, body, setValue }: Props) {
  useEffect(() => {
    keywordsStateVar(transformKeywords(keywords));
  }, []);

  function transformKeywords(keywords: Keyword[]) {
    const keywordsObject: any = {};

    for (let i = 0; i < keywords.length; i++) {
      const keyword = keywords[i];

      keywordsObject[keyword.id] = {
        isNew: false,
        id: keyword.id,
        key: keyword.key,
        value: keyword.value,
      };
    }
    return keywordsObject;
  }

  function mergedKeywords() {
    const mappedkeywords: KeywordArray = keywords.map((keyword: Keyword) => {
      return {
        id: keyword.id,
        key: keyword.key,
      };
    });
    return [...KEYWORDS, ...mappedkeywords];
  }
  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        size={"xs"}
        colorScheme="blue"
        variant="ghost"
        leftIcon={<PlusIcon className="w-4 h-4" />}
      >
        Insert keyword
      </MenuButton>

      <MenuList>
        {mergedKeywords().map((keyword: KeywordItem) => {
          return (
            <MenuItem
              key={keyword.id}
              onClick={() => {
                setValue("body", body + `{${keyword.key}}`);
              }}
            >
              {keyword.key}
            </MenuItem>
          );
        })}
        <MenuDivider />
      </MenuList>
    </Menu>
  );
}
