import { Divider, Input } from "@chakra-ui/react";
import {
  contactStatesVar,
  inputStatesVar,
  isEmailActionsVisibleVar,
} from "../../../../../store/messaging";

import Attachments from "./Attachments";
import EmailStyles from "./EmailStyles";
import { useReactiveVar } from "@apollo/client";

export default function EmailFields({ editor, contact }: any) {
  const inputStates = useReactiveVar(inputStatesVar);
  const contactStates = useReactiveVar(contactStatesVar);
  const emailActions = useReactiveVar(isEmailActionsVisibleVar);

  function handleChange(value: string) {
    inputStatesVar({ ...inputStates, emailSubject: value });
    if (contact) {
      contactStatesVar({
        ...contactStates,
        [contact.id]: {
          ...contactStates[contact.id],
          emailSubject: value,
        },
      });
    }
  }
  return (
    <div>
      <Input
        placeholder="Subject"
        type="text"
        variant="ghost"
        px={1}
        className="border-0 focus:ring-0 font-medium placeholder:font-normal"
        onKeyDown={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        value={inputStates.emailSubject}
        required={true}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
      <Attachments />
      <Divider mb={1} />
      {emailActions && <EmailStyles editor={editor} />}
    </div>
  );
}
