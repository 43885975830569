import { Heading, Text } from "@chakra-ui/react";
import { useEffect } from "react";

const reviewSlider = require("../../../assets/review_slider.png");
const reviewWidget = require("../../../assets/review_widget.png");
const reviewGrid = require("../../../assets/review_grid.png");
const feedbackWizard = require("../../../assets/feedback_wizard.png");
export function EmbedSelect({
  setSelectedLayout,
  selectedLayout,
  completeStep,
}: any) {
  const layouts = [
    {
      id: "slider",
      name: "Slider",
      description: "Three card slider with up to 20 reviews",
      image: reviewSlider,
    },
    {
      id: "widget",
      name: "Widget",
      description: "Small widget which sits above website content",
      image: reviewWidget,
    },
    {
      id: "grid",
      name: "Grid",
      description: "Simple two column grid with pagination",
      image: reviewGrid,
    },
    {
      id: "feedback-wizard",
      name: "Feedback Wizard",
      description: "A simple form to collect reviews & feedback",
      image: feedbackWizard,
    },
  ];

  return (
    <div>
      <div className="grid grid-cols-2 gap-4 mb-4 rounded-lg lg:grid-cols-2">
        {layouts.map((layout) => {
          return (
            <div
              className={` cursor-pointer  group border  p-1 rounded-lg ${
                selectedLayout === layout.id && "border-blue-500"
              }`}
              onClick={() => {
                setSelectedLayout(layout.id);
                completeStep(1);
              }}
              key={layout.id}
            >
              <div className="flex h-full gap-2 p-2 place-items-center">
                <img
                  className="object-contain w-20 h-full rounded-lg "
                  src={layout.image}
                  alt={layout.name}
                />
                <div className="p-1 mt-3">
                  <Heading size="sm" mb={1} className="font-medium">
                    {layout.name}
                  </Heading>
                  <Text>{layout.description}</Text>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
