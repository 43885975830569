import { Button, CircularProgress, Flex, useToast } from "@chakra-ui/react";
import { GET_TEMPLATES_AND_KEYWORDS } from "../../../apollo/templates";
import { Keyword } from "../../../types/Keyword";
import SettingsLayout from "../SettingsLayout";
import { Template } from "../../../types/Template";
import TemplateModal from "./TemplateModal";
import TemplatesTable from "./TemplatesTable";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import KeywordsModal from "./keywords/KeywordsModal";

type QueryData = {
  keywords: Keyword[];
  templates: Template[];
};

export default function TemplatesView() {
  const toast = useToast();
  const { loading, data } = useQuery<QueryData>(GET_TEMPLATES_AND_KEYWORDS, {
    onCompleted(data) {},
    onError(error) {
      console.log(error);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
  });

  return (
    <SettingsLayout
      name={"Message Templates"}
      description="Create message templates for commonly used messages"
      actions={
        data && (
          <div className="flex gap-2">
            <KeywordsModal keywords={data.keywords} />
            <TemplateModal keywords={data.keywords} />
          </div>
        )
      }
    >
      {loading && (
        <Flex alignItems="center" justifyContent="center">
          <CircularProgress isIndeterminate color="blue.400" />
        </Flex>
      )}

      {data && (
        <>
          <TemplatesTable keywords={data.keywords} templates={data.templates} />
        </>
      )}
    </SettingsLayout>
  );
}
