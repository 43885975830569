import {
  Avatar,
  Button,
  Center,
  CircularProgress,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import {
  Contact,
  ContactCollection,
  ContactEdge,
} from "../../../../types/Contact";
import { useLocation, useNavigate } from "react-router-dom";

import { FILTER_OPTIONS } from "../../../../constants/FILTER_OPTIONS";
import { GET_CONTACTS } from "../../../../apollo/contacts";
import { SCREENS } from "../../../../constants/SCREENS";
import { SORT_OPTIONS } from "../../../../constants/SORT_OPTIONS";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { useState } from "react";

type QueryData = {
  contacts: ContactCollection;
};

type Props = {
  isInbox?: boolean;
};

export default function ContactsTable({ isInbox }: Props) {
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);
  const [filterOption, setFilterOption] = useState(FILTER_OPTIONS[0]);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const [isDesktop] = useMediaQuery(SCREENS.DESKTOP);
  const [debouncedValue] = useDebounce(search, 1000);
  const isActive = location.pathname;

  function handleGroupMessage() {
    navigate("/inbox/group-message", { state: selectedContacts });
  }
  const { loading, data, fetchMore } = useQuery<QueryData>(GET_CONTACTS, {
    variables: {
      filter: {
        search: debouncedValue,
        channel: filterOption.channel,
      },
      sort: {
        field: sortOption.field,
        order: sortOption.value,
      },
      first: 20,
    },
    onError(error) {
      console.log(error);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
  });

  function handleScroll(e: React.UIEvent<HTMLDivElement>) {
    const endCursor = data!.contacts.pageInfo.endCursor;
    const hasNextPage = data!.contacts.pageInfo.hasNextPage;
    if (hasNextPage) {
      const target = e.target as HTMLElement;
      const bottom =
        target.scrollHeight - target.scrollTop === target.clientHeight;

      if (bottom) {
        fetchMore({
          variables: {
            after: endCursor,
          },
          updateQuery: (previousResultData, { fetchMoreResult }) => {
            const contacts = [
              ...previousResultData.contacts.edges,
              ...fetchMoreResult.contacts.edges,
            ];
            const pageInfo = {
              ...fetchMoreResult.contacts.pageInfo,
              hasPreviousPage:
                previousResultData.contacts.pageInfo.hasPreviousPage,
              startCursor: previousResultData.contacts.pageInfo.startCursor,
            };
            fetchMoreResult.contacts.edges = contacts;
            fetchMoreResult.contacts.pageInfo = pageInfo;
            return fetchMoreResult;
          },
        });
      }
    }
  }
  if (loading)
    return (
      <Center w="100%" h="100%">
        <CircularProgress isIndeterminate color="blue.400" />
      </Center>
    );
  if (data)
    return (
      <div className="h-full w-full relative">
        <div
          className="absolute inset-0 px-3 overflow-y-auto pt-2"
          onScroll={(e) => handleScroll(e)}
        >
          <div className="space-y-1">
            <div className="grid-cols-3 gap-2 text-sm font-medium py-3 hidden md:grid">
              <p>Name</p>
              <p>Email</p>
              <p>Phone</p>
            </div>
            {data.contacts.edges.map((contact: ContactEdge) => {
              return (
                <div
                  key={contact.node.id}
                  className={`hover:bg-gray-100  cursor-pointer col-span-4 grid md:grid-cols-3 p-2 items-center rounded-md ${
                    isActive ===
                      `${isInbox ? "/inbox" : "/contacts"}/${
                        contact.node.id
                      }` && "bg-gray-100"
                  }`}
                  onClick={() =>
                    navigate(
                      `${isInbox ? "/inbox" : "/contacts"}/${contact.node.id}`
                    )
                  }
                >
                  <div className="flex place-items-center gap-3">
                    <Avatar
                      name={contact.node.name}
                      src={contact.node.googleAvatar}
                      size="sm"
                    />
                    <p className="font-medium"> {contact.node.name}</p>
                  </div>

                  <div className="hidden md:block whitespace-nowrap truncate">
                    {contact.node.email}
                  </div>
                  <div className="hidden md:block">{contact.node.phone}</div>
                </div>
              );
            })}
          </div>
        </div>
        {!!selectedContacts.length && (
          <Button
            rightIcon={<PaperAirplaneIcon className="w-4 h-4" />}
            variant="solid"
            colorScheme="blue"
            position="fixed"
            className="z-10 shadow-xl bottom-4 right-4"
            onClick={() => handleGroupMessage()}
          >
            Group Message
          </Button>
        )}
      </div>
    );
  return null;
}
