import { gql } from "@apollo/client";

export const GET_ORGANISATION = gql`
  query GetUserDetails {
    googleAuthorisationLink {
      url
    }
    facebookAuthorisationLink {
      url
    }
    emails {
      id
      name
      email
    }
    facebookPages {
      id
      pageName
      pageToken
      pagePicture
      pageId
      organisationId
      lastScanned
      expiredAt
    }
    googleCredential {
      id
      publicReviewAPIKey
      expiredAt
      refreshTokenPresent
      locations {
        locationUrl
        name
        newReviewUrl
        title
        totalReviewCount
        averageRating
      }
    }
    me {
      id
      createdAt
      updatedAt
      name
      email
      role
      avatar
      emailVerified
      organisation {
        id
        name
        createdAt
        phones {
          phone
          id
          name
        }
        emails {
          id
          name
          email
        }
      }
    }
  }
`;

export const GET_GOOGLE_AUTHORISATION_LINK = gql`
  query GetUrl {
    googleAuthorisationLink {
      url
    }
  }
`;

export const GET_FACEBOOK_AUTHORISATION_LINK = gql`
  query GetUrl {
    facebookAuthorisationLink {
      url
    }
  }
`;

export const GET_GOOGLE_CREDENTIALS = gql`
  query GoogleCredential {
    googleCredential {
      id
      publicReviewAPIKey
      expiredAt
      refreshTokenPresent
      locations {
        locationUrl
        name
        newReviewUrl
        title
        totalReviewCount
        averageRating
      }
    }
  }
`;

export const UPDATE_ORGANISATION = gql`
  mutation UpdateOrganisation($name: String) {
    updateOrganisation(name: $name) {
      name
    }
  }
`;

export const DISCONNECT_EMAIL = gql`
  mutation DisconnectEmail($emailId: ID!) {
    disconnectEmail(id: $emailId) {
      email
    }
  }
`;

export const DISCONNECT_FACEBOOK_PAGE = gql`
  mutation DisconnectFacebookPage($pageId: ID!) {
    disconnectFacebookPage(pageId: $pageId) {
      pageId
    }
  }
`;
