import {
  GET_CONTACT_INBOX_EVENTS,
  SEND_REVIEW_REPLY,
} from "../../../../apollo/messaging";
import {
  contactStatesVar,
  inputStatesVar,
  reviewDataVar,
} from "../../../../store/messaging";
import { useMutation, useReactiveVar } from "@apollo/client";

export default function useReview(contactId: string) {
  const reviewData = useReactiveVar(reviewDataVar);
  const contactStates = useReactiveVar(contactStatesVar);
  const inputStates = useReactiveVar(inputStatesVar);

  const [
    handleSubmitReview,
    { data, loading: reviewLoading, error: reviewError },
  ] = useMutation(SEND_REVIEW_REPLY, {
    variables: {
      commentOnGoogleReviewId: reviewData.message_id,
      comment: {
        comment: inputStates.reviewReply,
        name: reviewData.contact_google_url,
      },
    },

    onCompleted(data) {
      if (contactId) {
        contactStatesVar({
          ...contactStates,
          [contactId]: {
            ...contactStates[contactId],
            reviewReply: "",
          },
        });
      }
    },
    refetchQueries: [
      {
        query: GET_CONTACT_INBOX_EVENTS,
        variables: { contactId: contactId },
      },
      "GetContactInboxEvents",
    ],
    onError(error) {
      console.log(error);
    },
  });

  return { handleSubmitReview, reviewLoading, reviewError };
}
