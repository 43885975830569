import * as yup from "yup";

import {
  Button,
  Center,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { GET_USER_DETAILS, UPDATE_USER } from "../../../apollo/user";
import { useMutation, useQuery } from "@apollo/client";
import SettingsLayout from "../SettingsLayout";
import UpdatePassword from "./UpdatePassword";
import { UserIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UpdateEmail from "./UpdateEmail";
import SettingsRow from "../SettingsRow";

type FormData = {
  name: string | null;
  email: string | null;
};

const schema = yup.object().shape({
  name: yup.string().min(4, "Too short"),
  email: yup.string().email().required("Must be a valid email"),
});

export default function UserView() {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
    },
  });

  const { data, refetch } = useQuery(GET_USER_DETAILS, {
    onError(error) {
      console.log(error);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
  });

  useEffect(() => {
    if (data) {
      setValue("name", data.me.name);
      setValue("email", data.me.email);
    }
  }, [data, setValue]);

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      toast({
        title: "User updated",
        status: "success",
      });
      console.log(data);
    },
    onError: (error) => {
      error.graphQLErrors.forEach((error) => {
        switch (error.extensions.code) {
          case "EMAIL_TAKEN":
            setError("email", {
              type: "server",
              message: error.message,
            });
            break;
          default:
            toast({
              title: "Error occured",
              description: error.message,
              status: "error",
            });
        }
      });
    },
    refetchQueries: [{ query: GET_USER_DETAILS }, "GetUserDetails"],
  });

  async function onSubmit(values: FormData) {
    const originalUserData = { name: data.me.name, email: data.me.email };

    // Determine which fields have changed
    const updatedFields: any = {};

    Object.keys(originalUserData).forEach((field) => {
      if (
        values[field as keyof FormData] !==
        originalUserData[field as keyof FormData]
      ) {
        updatedFields[field] = values[field as keyof FormData];
      }
    });
    if (Object.keys(updatedFields).length > 0) {
      updateUser({
        variables: updatedFields,
      });
    } else {
    }
  }

  if (loading)
    return (
      <Center w="100%" h="100%">
        <CircularProgress isIndeterminate color="blue.400" />
      </Center>
    );
  if (data)
    return (
      <SettingsLayout
        name="Profile"
        description="Manage your personal details"
        actions={
          <Button
            variant="solid"
            colorScheme="blue"
            type="submit"
            size="md"
            form="user-details"
            isLoading={loading}
          >
            Save Profile
          </Button>
        }
      >
        <form id="user-details" onSubmit={handleSubmit(onSubmit)}>
          <SettingsRow
            title="Name"
            description="Your name identifier for customers"
            action={
              <FormControl isInvalid={!!errors.name}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <UserIcon className="w-5 h-5" />
                  </InputLeftElement>
                  <Input
                    placeholder="i.e John Smith"
                    autoComplete="name"
                    id="name"
                    {...register("name")}
                  />
                </InputGroup>
                {!!errors.name && (
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                )}
              </FormControl>
            }
          />
        </form>

        <SettingsRow
          title="Email"
          description={data.me.email}
          action={<UpdateEmail />}
        />
        <SettingsRow
          title="Password"
          description="Change your password"
          action={<UpdatePassword />}
        />
      </SettingsLayout>
    );
  return null;
}
