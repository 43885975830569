import {
  Avatar,
  Center,
  CircularProgress,
  Flex,
  Heading,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import BackButton from "../../../components/BackButton";
import { Contact } from "../../../types/Contact";
import ContactForm from "./ContactForm";
import DeleteContact from "../contacts_view/table/DeleteContact";
import { GET_CONTACT_DETAILS } from "../../../apollo/contact";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { RiChat1Line } from "react-icons/ri";
import getContactName from "../../../utilities/getContactName";
import { useQuery } from "@apollo/client";

type QueryData = {
  contact: Contact;
};

export default function ContactView() {
  const { contactId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { loading, data } = useQuery<QueryData>(GET_CONTACT_DETAILS, {
    variables: { contactId: contactId },
    onError(error) {
      console.log(error);
      toast({
        title: "Error occurred",
        description: error.message,
        status: "error",
      });
    },
  });
  if (data)
    return (
      <div className="w-full">
        <div className="flex justify-between gap-2 p-2 border-b place-items-center">
          <div className="flex gap-1 place-items-center">
            <BackButton />
            <div className="flex gap-3 place-items-center">
              <Avatar
                size="sm"
                name={data.contact.name}
                src={data.contact.googleAvatar}
              />
              <h2 className="text-lg font-semibold">
                {getContactName(data.contact)}
              </h2>
            </div>
          </div>
          <IconButton
            variant="ghost"
            aria-label=""
            onClick={() => navigate(`/inbox/${data.contact.id}`)}
          >
            <PencilSquareIcon className="h-5 w-5" />
          </IconButton>
        </div>
        <div className=" mx-auto p-4 space-y-6">
          <ContactForm contact={data.contact} />
        </div>
      </div>
    );

  if (loading)
    return (
      <Center w="100%" h="100%">
        <CircularProgress isIndeterminate color="blue.400" />
      </Center>
    );

  return null;
}
