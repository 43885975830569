import { gql } from "@apollo/client";

export const GET_USER_DETAILS = gql`
  query GetUserDetails {
    me {
      id
      createdAt
      updatedAt
      name
      email
      role
      avatar
      emailVerified
      organisation {
        id
        name
        createdAt
        phones {
          phone
          id
          name
        }
        emails {
          id
          name
          email
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($name: String, $avatarURL: String) {
    updateUser(name: $name, avatarURL: $avatarURL) {
      name
      email
      avatar
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($currentPassword: String, $newPassword: String) {
    updateUserPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      name
    }
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($email: String, $password: String) {
    updateUserEmail(email: $email, password: $password) {
      email
      email
      avatar
    }
  }
`;

export const ENABLE_NOTIFICATIONS = gql`
  mutation EnableNotifications(
    $subscription: NotificationSubscriptionInputType!
  ) {
    enableNotifications(subscription: $subscription) {
      name
    }
  }
`;

export const DISABLE_NOTIFICATIONS = gql`
  mutation DisableNotifications($endpoint: String!) {
    disableNotifications(endpoint: $endpoint) {
      name
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $email: String
    $inviteToken: String
    $role: String
    $id: String
    $name: String
    $organisation: String
  ) {
    createUser(
      email: $email
      inviteToken: $inviteToken
      role: $role
      id: $id
      name: $name
      organisation: $organisation
    ) {
      id
      name
      email
      organisation {
        id
      }
    }
  }
`;
