import { BiArchiveIn, BiArchiveOut } from "react-icons/bi";
import { Button, Text, useToast } from "@chakra-ui/react";
import {
  CLOSE_MULTIPLE_CONTACTS,
  GET_INBOX_CONTACTS,
} from "../../../../apollo/inbox";

import { useMutation, useReactiveVar } from "@apollo/client";
import { isOpenVar, multiSelectVar } from "src/store/inbox";

export default function ToggleContacts() {
  const toast = useToast();
  const multiSelect = useReactiveVar(multiSelectVar);
  const isOpen = useReactiveVar(isOpenVar);
  const [closeMultipleContacts, { loading: mutationLoading }] = useMutation(
    CLOSE_MULTIPLE_CONTACTS,
    {
      variables: {
        selectedContacts: multiSelect.selectedContacts,
        open: !isOpen,
      },
      refetchQueries: [
        {
          query: GET_INBOX_CONTACTS,
        },
        "GetInboxContacts",
      ],
      onCompleted: (data) => {
        multiSelectVar({ ...multiSelect, selectedContacts: [] });
      },
      onError(error) {
        toast({
          title: "Error occured",
          description: error.message,
          status: "error",
        });
      },
    }
  );
  return (
    <div className="flex gap-4 justify-between  px-3 py-2 place-items-center">
      <p className="text-sm font-medium">
        {multiSelect.selectedContacts.length} Selected
      </p>
      <Button
        size="sm"
        variant="ghost"
        leftIcon={isOpen ? <BiArchiveIn /> : <BiArchiveOut />}
        className=""
        onClick={() => closeMultipleContacts()}
        isLoading={mutationLoading}
      >
        {!mutationLoading && isOpen
          ? `Close Conversations`
          : `Open Conversations`}
      </Button>
    </div>
  );
}
