import { Button, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SettingsRow from "../SettingsRow";
import SettingsLayout from "../SettingsLayout";
import { DISABLE_NOTIFICATIONS, ENABLE_NOTIFICATIONS } from "src/apollo/user";
import { useMutation } from "@apollo/client";
import { useSettings } from "src/hooks/useSettings";

export default function ApplicationView() {
  const [notifications, setNotifications] = useState(false);
  const [supportsNotifications, setSupportsNotifications] = useState(false);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);
  const { settings, setSettings } = useSettings();
  const toast = useToast();
  const publicVapidKey =
    "BHtdExc92cMAWFvc7BlfFfrYHBNwhVZZXXm523YxXuoHtLs5CoSxKA-kBg0FTig6H3dxxUjjmOdkrjKzNRbAVII";
  const [enabledNotifications, { loading: enabledNotificationsLoading }] =
    useMutation(ENABLE_NOTIFICATIONS, {
      onCompleted: (data) => {
        setNotifications(true);
        toast({
          title: "Notifications enabled",
          status: "success",
        });
      },
      onError: (error) => {
        toast({
          title: "Failed to enable notifications",
          description: error.message,
          status: "error",
        });
      },
    });

  const [disabledNotifications, { loading: disabledNotificationsLoading }] =
    useMutation(DISABLE_NOTIFICATIONS, {
      onCompleted: (data) => {
        setNotifications(false);
        toast({
          title: "Notifications disabled",
          status: "success",
        });
      },
      onError: (error) => {
        toast({
          title: "Failed to disable notifications",
          description: error.message,
          status: "error",
        });
      },
    });

  const getSubscription = async () => {
    try {
      const registration = await navigator.serviceWorker.ready;

      if (!registration.pushManager) {
        console.log("[SW] Push manager is not supported");
        setSupportsNotifications(false);
        return;
      }

      setSupportsNotifications(true);
      return await registration.pushManager.getSubscription();
    } catch (error) {
      console.error("Error getting subscription:", error);
      setSupportsNotifications(false);
    }
  };

  const toggleNotifications = async () => {
    if (!("serviceWorker" in navigator)) {
      console.log("[SW] Service workers are not supported");
      toast({
        title: "Service workers not supported",
        description: "Notifications cannot be enabled.",
        status: "error",
      });
      return;
    }

    if (!("PushManager" in window)) {
      console.log("[SW] Push notifications are not supported");
      toast({
        title: "Push notifications not supported",
        description: "Notifications cannot be enabled.",
        status: "error",
      });
      return;
    }

    try {
      const result = await Notification.requestPermission();

      if (result !== "granted") {
        console.log("[SW] User denied permission");
        setNotifications(false);
        toast({
          title: "Permission denied",
          description: "Notifications cannot be enabled.",
          status: "error",
        });
        return;
      }

      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();

      if (subscription) {
        await disabledNotifications({
          variables: {
            endpoint: subscription.endpoint,
          },
        });

        await subscription.unsubscribe();
        console.log("[SW] Notifications disabled");
      } else {
        const newSubscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: publicVapidKey,
        });

        await enabledNotifications({
          variables: {
            subscription: newSubscription,
          },
        });

        console.log("[SW] Notifications enabled");
      }
    } catch (error: any) {
      console.error("Error toggling notifications:", error);
      toast({
        title: "Failed to toggle notifications",
        description: error.message,
        status: "error",
      });
    }
  };

  const installPWAPrompt = async () => {
    console.log("Attempting to install PWA");

    if (!("serviceWorker" in navigator)) {
      console.error("[SW] Service workers are not supported");
      toast({
        title: "Service workers not supported",
        description: "PWA installation is not possible.",
        status: "error",
      });
      return;
    }

    if (!("PushManager" in window)) {
      console.error("[SW] Push notifications are not supported");
      toast({
        title: "Push notifications not supported",
        description: "PWA installation is not possible.",
        status: "error",
      });
      return;
    }

    if (!promptInstall) {
      console.warn("Install prompt not available");
      toast({
        title: "Install prompt unavailable",
        description: "Please try again later.",
        status: "warning",
      });
      return;
    }

    try {
      promptInstall.prompt();
      const choiceResult = await promptInstall.userChoice;

      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the PWA installation");
        toast({
          title: "PWA installation accepted",
          status: "success",
        });
      } else {
        console.log("User dismissed the PWA installation");
        toast({
          title: "PWA installation dismissed",
          status: "info",
        });
      }
    } catch (error: any) {
      console.error("Error prompting the install:", error);
      toast({
        title: "Failed to prompt install",
        description: error.message,
        status: "error",
      });
    }
  };

  useEffect(() => {
    getSubscription().then((subscription) => {
      setNotifications(subscription !== null);
    });

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if (registration.active) {
          const ua = navigator.userAgent;

          const isInStandaloneMode = () =>
            window.matchMedia("(display-mode: standalone)").matches ||
            (navigator as any).standalone;

          const isSupportingPlatform = !/iPad|iPhone|iPod/.test(ua);

          if (!isInStandaloneMode() && isSupportingPlatform) {
            setSupportsPWA(true);
          }
        }
      });
    }

    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault();
      setPromptInstall(event);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  return (
    <SettingsLayout
      name="Application"
      description="Update your application settings"
      // actions={}
    >
      {supportsPWA && (
        <SettingsRow
          title="Install PWA"
          description="Install Smartyr as a PWA on this device."
          action={
            <Button onClick={installPWAPrompt} variant="outline" size="sm">
              Install
            </Button>
          }
        ></SettingsRow>
      )}
      {supportsNotifications && (
        <SettingsRow
          title="Notifications"
          description="Choose whether to receive notifications from Smartyr on this device."
          action={
            <Button
              onClick={toggleNotifications}
              variant="outline"
              size="sm"
              disabled={
                enabledNotificationsLoading || disabledNotificationsLoading
              }
            >
              {enabledNotificationsLoading || disabledNotificationsLoading
                ? "Loading..."
                : notifications
                ? "Disable"
                : "Enable"}
            </Button>
          }
        ></SettingsRow>
      )}
      <SettingsRow
        title="Enter to send"
        description="When enabled, the enter button will automatically send the message you are typing. To go to a new line, press shift + enter."
        action={
          <Button
            onClick={() => {
              setSettings({ ...settings, enterToSend: !settings.enterToSend });
              toast({
                title: `Enter to send ${
                  settings.enterToSend ? "disabled" : "enabled"
                }`,
                status: "success",
              });
            }}
            variant="outline"
            size="sm"
          >
            {settings.enterToSend ? "Disable" : "Enable"}
          </Button>
        }
      ></SettingsRow>
    </SettingsLayout>
  );
}
