export const KEYWORDS = [
  {
    id: "32525",
    key: "user.name",
    value: "Current users name",
  },
  {
    id: "23153",
    key: "contact.name",
    value: "Contacts name",
  },
  {
    id: "23553",
    key: "google.review",
    value: "Google Review URL",
  },
] as const;
