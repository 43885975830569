import { Center, Flex, Text } from "@chakra-ui/react";

import { CHANNELS } from "../../../../constants/CHANNELS";

type Props = {
  previousChannel: string | undefined;
  channel: string;
};

export default function ChannelIndicator({ previousChannel, channel }: Props) {
  if (!previousChannel || previousChannel !== channel) {
    return (
      <div className="w-full py-3 flex place-items-center justify-center">
        <div className="flex gap-1 nowrap place-items-center">
          {CHANNELS[channel as keyof typeof CHANNELS].iconRegular}
          <Text fontSize="sm" fontWeight="medium">
            {CHANNELS[channel as keyof typeof CHANNELS].name}
          </Text>
        </div>
      </div>
    );
  }
  return null;
}
