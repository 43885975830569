import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  CREATE_TEMPLATE,
  GET_TEMPLATES_AND_KEYWORDS,
  UPDATE_TEMPLATE,
} from "../../../apollo/templates";

import { Keyword } from "../../../types/Keyword";
import KeywordsMenu from "./SelectKeyword";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Template } from "../../../types/Template";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

type Props = {
  keywords: Keyword[];
  template?: Template;
};

type FormData = {
  name: string;
  body: string;
};
export default function TemplateModal({ keywords, template }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const isEditing = template;

  useEffect(() => {
    if (isEditing) {
      setValue("name", template.name);
      setValue("body", template.body);
    }
  }, [isEditing]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      name: "",
      body: "",
    },
  });

  const [createTemplate, { loading: createLoading }] = useMutation(
    CREATE_TEMPLATE,
    {
      refetchQueries: [
        { query: GET_TEMPLATES_AND_KEYWORDS },
        "GetTemplatesAndKeywords",
      ],
    }
  );

  const [updateTemplate, { loading }] = useMutation(UPDATE_TEMPLATE, {
    refetchQueries: [
      { query: GET_TEMPLATES_AND_KEYWORDS },
      "GetTemplatesAndKeywords",
    ],
  });

  function errorHandler(error: any) {
    error.graphQLErrors.forEach((error: any) => {
      switch (error.extensions.code) {
        case "NAME_TAKEN":
          setError("name", {
            type: "server",
            message: error.message,
          });
          break;
        default:
          toast({
            title: "Error occurred",
            description: error.message,
            status: "error",
          });
      }
    });
  }

  function onSubmit(values: FormData) {
    isEditing
      ? updateTemplate({
          variables: {
            updateTemplateId: template.id,
            template: {
              name: values.name,
              body: values.body,
            },
          },
          onError(error) {
            errorHandler(error);
          },
          onCompleted() {
            onClose();
            reset();
          },
        })
      : createTemplate({
          variables: {
            template: {
              name: values.name,
              body: values.body,
            },
          },
          onError(error) {
            errorHandler(error);
          },
          onCompleted() {
            onClose();
            reset();
          },
        });
  }

  return (
    <>
      {isEditing ? (
        <IconButton aria-label="Edit Template" variant="ghost" onClick={onOpen}>
          <PencilSquareIcon className="w-5 h-5" />
        </IconButton>
      ) : (
        <Button onClick={onOpen} colorScheme="blue" size="sm">
          New Template
        </Button>
      )}
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isEditing ? "Edit Template" : "Create Template"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form
              id="template"
              className="space-y-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl isInvalid={!!errors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  type="text"
                  placeholder="i.e Review request"
                  {...register("name", { required: "Name is required" })}
                />
                {errors.name && (
                  <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.body}>
                <Flex justifyContent="space-between" alignItems="center" mb={1}>
                  <FormLabel mb={0}>Message</FormLabel>

                  <KeywordsMenu
                    keywords={keywords}
                    setValue={setValue}
                    body={getValues("body")}
                  />
                </Flex>
                <Textarea
                  placeholder="i.e Hey {contact.name}, thanks for using our services..."
                  rows={6}
                  {...register("body", { required: "Message is required." })}
                ></Textarea>
                {errors.body && (
                  <FormErrorMessage>{errors.body.message}</FormErrorMessage>
                )}
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              isLoading={createLoading || loading}
              type="submit"
              form="template"
              variant="solid"
              colorScheme="blue"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
