import { Avatar } from "@chakra-ui/react";
import { useState } from "react";
import { InboxEvent } from "src/types/InboxEvent";
import { dateFormatter } from "src/utilities/dateFormatter";

type Props = {
  inboxEvent: InboxEvent;
};

export default function Feedback({ inboxEvent }: Props) {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const { createdAt, id, direction, properties, contact } = inboxEvent;

  if ("experience" in properties && direction === "RECEIVED") {
    return (
      <div className="flex gap-2  justify-start max-w-[95%] md:max-w-[80%]">
        <Avatar {...contact} />
        <div className="flex flex-col place-items-start mb-3">
          <div
            className=" bg-gray-100 p-3 rounded-tl-md rounded-xl"
            onClick={() => setIsDetailsVisible(!isDetailsVisible)}
          >
            <p className="paragraph-regular font-semibold">
              Sentiment:{" "}
              <span>
                {properties.experience === "positive"
                  ? "🙂"
                  : properties.experience === "negative"
                  ? "🙁"
                  : "😐"}
              </span>
            </p>
            <p className="paragraph-regular">{properties.message}</p>
          </div>
          {isDetailsVisible && (
            <p className="text-sm text-gray-600 mt-px">
              {dateFormatter(createdAt)}
            </p>
          )}
        </div>
      </div>
    );
  }
  //   if ("chat" in properties && direction === "SENT")
  //     return (
  //       <div className="flex max-w-[95%] md:max-w-[80%] ml-auto justify-end gap-2 mb-2 ">
  //         <div className="flex place-items-end flex-col">
  //           <div
  //             className=" bg-blue-500 p-3 rounded-tr-md rounded-xl"
  //             onClick={() => setIsDetailsVisible(!isDetailsVisible)}
  //           >
  //             <p className="paragraph-regular text-white">{properties.message}</p>
  //           </div>
  //         </div>
  //         <Avatar {...inboxEvent.sender} />
  //       </div>
  //     );
  return null;
}
