import { IconButton, useMediaQuery } from "@chakra-ui/react";

import { SCREENS } from "../constants/SCREENS";
import { useNavigate } from "react-router-dom";
import {RiArrowLeftSLine } from "react-icons/ri";

export default function BackButton({ hideDesktop }: any) {
  const navigate = useNavigate();
  const [isDesktop] = useMediaQuery(SCREENS.DESKTOP);
  if (hideDesktop && isDesktop) return null;
  return (
    <IconButton
      aria-label="Navigate Back"
      variant="ghost"
      onClick={() => navigate(-1)}
    >
      <RiArrowLeftSLine />
    </IconButton>
  );
}
