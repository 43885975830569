import { Flex, IconButton } from "@chakra-ui/react";
import {
  inputStatesVar,
  isEmailActionsVisibleVar,
} from "../../../../../store/messaging";

import FileAttachment from "../attachments/FileAttachment";
import ChannelSelect from "./ChannelSelect";
import { RiFontColor } from "react-icons/ri";
import SendButton from "./SendButton";
import TemplateSelect from "./TemplateSelect";
import { useReactiveVar } from "@apollo/client";
import ImageAttachment from "../attachments/ImageAttachment";

export default function InputActions({
  contact,
  contacts,
  channels,
  editor,
}: any) {
  const inputStates = useReactiveVar(inputStatesVar);
  const emailActions = useReactiveVar(isEmailActionsVisibleVar);

  const imageChannels = ["FACEBOOK_CHAT", "SMS"];
  const fileChannels = ["EMAIL"];

  return (
    <Flex alignItems="end" justifyContent="space-between" gap="1px">
      <Flex alignItems="end" gap="1px">
        <ChannelSelect
          contacts={contacts}
          contact={contact}
          channels={channels}
        />
        <TemplateSelect contact={contact} editor={editor} />
        {inputStates.selectedChannel === "EMAIL" && (
          <IconButton
            variant={"ghost"}
            aria-label="Text styles panel"
            onClick={() => isEmailActionsVisibleVar(!emailActions)}
          >
            <RiFontColor />
          </IconButton>
        )}
        {imageChannels.includes(inputStates.selectedChannel as string) && (
          <ImageAttachment />
        )}
        {fileChannels.includes(inputStates.selectedChannel as string) && (
          <FileAttachment />
        )}
      </Flex>
      <SendButton contacts={contacts} contact={contact} channels={channels} />
    </Flex>
  );
}
