import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  attachmentStateVar,
  contactStatesVar,
  inputStatesVar,
} from "../../../../../store/messaging";

import { CHANNELS } from "../../../../../constants/CHANNELS";
import { CHANNELS_SELECT } from "../../../../../constants/CHANNELS_SELECT";
import { Contact } from "../../../../../types/Contact";
import { useReactiveVar } from "@apollo/client";

type Props = {
  contact: Contact | undefined;
  contacts: Contact[] | undefined;
  channels: string[];
};

export default function ChannelSelect({ contact, contacts, channels }: Props) {
  const contactStates = useReactiveVar(contactStatesVar);
  const inputStates = useReactiveVar(inputStatesVar);
  const attachmentState = useReactiveVar(attachmentStateVar);

  function handleChannelSelect(value: string) {
    attachmentStateVar(null);
    inputStatesVar({
      ...inputStates,
      selectedChannel: value,
    });
    if (contact) {
      contactStatesVar({
        ...contactStates,
        [contact.id]: {
          ...contactStates[contact.id],
          selectedChannel: value,
        },
      });
    }
  }
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Select Channel"
        variant="ghost"
        className={`${
          CHANNELS_SELECT[inputStates.selectedChannel as keyof typeof CHANNELS]
            .buttonColors
        }`}
      >
        {
          CHANNELS_SELECT[inputStates.selectedChannel as keyof typeof CHANNELS]
            .icon
        }
      </MenuButton>
      <MenuList>
        {channels.map((channel: string) => (
          <MenuItem
            onClick={() => handleChannelSelect(channel)}
            key={channel}
            icon={CHANNELS_SELECT[channel as keyof typeof CHANNELS].icon}
            aria-label={""}
          >
            {CHANNELS_SELECT[channel as keyof typeof CHANNELS].name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
