import { makeVar } from "@apollo/client";
import { SORT_OPTIONS } from "../constants/SORT_OPTIONS";

//Inbox Page Variables
type SortOption = {
  field: string;
  value: string;
  label: string;
};

type MultiSelectVar = {
  isMultiSelectActive: boolean;
  selectedContacts: string[];
};

export const isInboxFilterActiveVar = makeVar<boolean>(false);
export const sortOptionVar = makeVar<SortOption>(SORT_OPTIONS[0]);
export const searchVar = makeVar<string>("");
export const multiSelectVar = makeVar<MultiSelectVar>({
  isMultiSelectActive: false,
  selectedContacts: [],
});
export const isOpenVar = makeVar<boolean>(true);
