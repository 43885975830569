import {
  Box,
  CircularProgress,
  Flex,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  useSteps,
} from "@chakra-ui/react";

import EmbedCopy from "./EmbedCopy";
import { EmbedSelect } from "./EmbedSelect";
import { GET_GOOGLE_CREDENTIALS } from "../../../apollo/organisation";
import GoogleAuthentication from "../integrations/GoogleAuthentication";
import { GoogleCredential } from "../../../types/Organisation";
import { ScriptCopy } from "./ScriptCopy";
import SettingsLayout from "../SettingsLayout";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

type QueryData = {
  googleCredential: GoogleCredential;
};

export default function ReviewsEmbedPage() {
  const { loading, data } = useQuery<QueryData>(GET_GOOGLE_CREDENTIALS, {
    onCompleted(data) {},
    onError(error) {
      console.log(error);
    },
  });

  const [selectedLayout, setSelectedLayout] = useState("");
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 4,
  });

  const completeStep = (index: number) => {
    setActiveStep(index + 1);

    const step = document.getElementById(`step-${index.toString()}`);

    if (step) {
      setTimeout(() => {
        step.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const steps = [
    {
      title: "Add scripts to website head",
      description:
        "You only need to add this once, no matter how many embeds you use.",
      card: <ScriptCopy completeStep={completeStep} />,
    },
    {
      title: "Select embed type",
      description: "Choose a layout that fits your website",
      card: (
        <EmbedSelect
          selectedLayout={selectedLayout}
          setSelectedLayout={setSelectedLayout}
          completeStep={completeStep}
        />
      ),
    },
    {
      title: "Paste code where you want to show the embed",
      description: "Script will add the embed where you place this code",
      card: (
        <EmbedCopy
          selectedLayout={selectedLayout}
          googleCredential={data?.googleCredential}
          completeStep={completeStep}
        />
      ),
    },
    {
      title: "Congratulations!",
      description:
        "You're all set up. Embeds should now show up on your website, if you run into any issues, please contact support.",
      card: <></>,
    },
  ];

  return (
    <SettingsLayout
      name="Reviews Embed"
      description="Add reviews to your website"
    >
      {loading && (
        <Flex justifyContent="center">
          <CircularProgress isIndeterminate color="blue.400" />
        </Flex>
      )}
      {data && !data.googleCredential && (
        <Text>Connect your google account to access review embeds.</Text>
      )}
      {data && data.googleCredential && (
        <Stepper
          index={activeStep}
          orientation="vertical"
          gap="0"
          size={"lg"}
          colorScheme="green"
          width={"100%"}
        >
          {steps
            .filter((step, index) => index <= activeStep)
            .map((step, index) => (
              <Step key={index} className="w-full">
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
                <Box ml={6} width={"100%"}>
                  <Flex justifyContent="space-between" gap={12}>
                    <Box mb={4} maxWidth={700}>
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>
                  </Flex>
                  <div id={`step-${index}`}>
                    <Box mb={16}>{step.card}</Box>
                  </div>
                </Box>
                <StepSeparator />
              </Step>
            ))}
        </Stepper>
      )}
    </SettingsLayout>
  );
}
