import { CHANNELS } from "../constants/CHANNELS";
import { Contact } from "../types/Contact";

export default function getContactChannelIcons(contact: Contact) {
  const contactChannels = [];
  if (contact.phone) {
    contactChannels.push("SMS");
  }
  if (contact.googleAvatar) {
    contactChannels.push("REVIEW");
  }
  if (contact.email) {
    contactChannels.push("EMAIL");
  }
  if (contact.facebookId) {
    contactChannels.push("FACEBOOK_CHAT");
  }
  return contactChannels.map((channel) => {
    return (
      <div key={channel}>
        {CHANNELS[channel as keyof typeof CHANNELS].iconSmall}
      </div>
    );
  });
}
