import { Avatar, IconButton, Tooltip, useToast } from "@chakra-ui/react";
import { GET_INBOX_CONTACTS, TOGGLE_CONTACT } from "../../../apollo/inbox";

import { ArchiveBoxArrowDownIcon, UserIcon } from "@heroicons/react/24/outline";
import BackButton from "../../../components/BackButton";
import { Bars3BottomRightIcon } from "@heroicons/react/24/solid";
import { Contact } from "../../../types/Contact";
import getContactName from "../../../utilities/getContactName";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

type Props = {
  isSideBarVisible: boolean;
  setIsSideBarVisible: React.Dispatch<React.SetStateAction<boolean>>;
  contact: Contact;
};

export default function MessageAppBar({
  isSideBarVisible,
  setIsSideBarVisible,
  contact,
}: Props) {
  const toast = useToast();
  const navigate = useNavigate();
  const [toggleContact] = useMutation(TOGGLE_CONTACT, {
    variables: {
      toggleOpenContactId: contact.id,
    },
    refetchQueries: [
      {
        query: GET_INBOX_CONTACTS,
        variables: { open: !contact.open },
      },
      "GetInboxContacts",
    ],

    onCompleted: (data) => {
      toast({
        title: "Success",
        description: `Moved contact to ${
          data.toggleOpenContact.open ? "open" : "closed"
        }`,
        status: "success",
      });
    },
    onError: (error) => {
      toast({
        title: "Error occurred.",
        description: error.message,
        status: "error",
      });
    },
  });
  return (
    <div className="flex justify-between place-items-center border-b p-2">
      <div className="flex place-items-center gap-1">
        <BackButton />
        <div className="flex place-items-center gap-3">
          <Avatar
            height={9}
            width={9}
            name={contact.name}
            src={contact.googleAvatar}
          />
          <h2 className="text-lg font-semibold">{getContactName(contact)}</h2>
        </div>
      </div>
      <div className="flex gap-1">
        <Tooltip
          label={contact.open ? "Close Contact" : "Open Contact"}
          openDelay={500}
        >
          <IconButton
            variant="ghost"
            aria-label="Toggle Contact"
            onClick={() => toggleContact()}
          >
            <ArchiveBoxArrowDownIcon className="w-5 h-5" />
          </IconButton>
        </Tooltip>

        <Tooltip
          label={isSideBarVisible ? "Hide Sidebar" : "Show Sidebar"}
          openDelay={500}
        >
          <IconButton
            variant="ghost"
            aria-label="Show/Hide Sidebar"
            onClick={() => {
              setIsSideBarVisible(!isSideBarVisible);
            }}
          >
            <UserIcon className="h-5 w-5" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
