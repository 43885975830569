import {
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  StarIcon,
  ChatBubbleOvalLeftIcon,
  Cog6ToothIcon,
  UsersIcon,
  ChartBarIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import { LiaFacebook } from "react-icons/lia";
import { LiaFacebookMessenger } from "react-icons/lia";

export const CHANNELS = {
  SMS: {
    name: "Text Message",
    iconSmall: <DevicePhoneMobileIcon className="flex-shrink-0 h-3" />,
    iconRegular: <DevicePhoneMobileIcon className="flex-shrink-0 w-4 h-4" />,
    iconLarge: <DevicePhoneMobileIcon className="flex-shrink-0 h-5" />,
    buttonColors: "bg-purple-100 text-purple-800 hover:bg-purple-200",
  },
  REVIEW: {
    name: "Review",
    iconSmall: <StarIcon className="flex-shrink-0 h-3" />,
    iconRegular: <StarIcon className="flex-shrink-0 w-4 h-4" />,
    iconLarge: <StarIcon className="w-5 h-5" />,
    buttonColors: "bg-amber-100 text-amber-800 hover:bg-amber-200",
  },
  EMAIL: {
    name: "Email",
    iconSmall: <EnvelopeIcon className="flex-shrink-0 h-3" />,
    iconRegular: <EnvelopeIcon className="flex-shrink-0 h-4" />,
    iconLarge: <EnvelopeIcon className="w-5 h-5" />,
    buttonColors: "bg-green-100 text-green-800 hover:bg-green-200",
  },
  FACEBOOK_CHAT: {
    name: "Facebook Messenger",
    iconSmall: <LiaFacebookMessenger className="flex-shrink-0 h-3" />,
    iconRegular: <LiaFacebookMessenger className="flex-shrink-0 w-4 h-4" />,
    iconLarge: <LiaFacebookMessenger className="w-5 h-5" />,
    buttonColors: "bg-blue-100 text-blue-800 hover:bg-blue-200",
  },
  FACEBOOK_RECOMMENDATION: {
    name: "Facebook Recommendation",
    iconSmall: <LiaFacebook className="flex-shrink-0 h-3" />,
    iconRegular: <LiaFacebook className="flex-shrink-0 w-4 h-4" />,
    iconLarge: <LiaFacebook className="w-5 h-5" />,
    buttonColors: "bg-blue-100 text-blue-800 hover:bg-blue-200",
  },
  FEEDBACK: {
    name: "Feedback",
    iconSmall: <HandThumbUpIcon className="flex-shrink-0 h-3" />,
    iconRegular: <HandThumbUpIcon className="flex-shrink-0 w-4 h-4" />,
    iconLarge: <HandThumbUpIcon className="w-5 h-5" />,
    buttonColors: "bg-green-100 text-green-800 hover:bg-green-200",
  },
};
