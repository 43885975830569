import { NAVLINKS } from "../constants/NAVLINKS";
import { NavLink } from "react-router-dom";
import mergeClassNames from "../utilities/mergeClassNames";
import { Avatar } from "@chakra-ui/react";

export default function MobileNavBar() {
  return (
    <div className="relative z-20 grid grid-cols-3 w-full bg-white border-t place-items-center">
      {NAVLINKS.map((NAVLINK: any) => {
        return (
          <NavLink
            key={NAVLINK.path}
            to={NAVLINK.path}
            className={({ isActive }) =>
              mergeClassNames(
                isActive
                  ? "text-blue-500"
                  : "text-gray-400 hover:text-blue-500",
                "w-full py-4 flex place-items-center justify-center  cursor-pointer transition-colors duration-200 ease-in-out"
              )
            }
          >
            {NAVLINK.icon}
          </NavLink>
        );
      })}
    </div>
  );
}
