import React from "react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
export default function NoChannels({ contact }: any) {
  const navigate = useNavigate();
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Select Channel"
        variant="solid"
        colorScheme="blue"
      >
        <PlusIcon className="w-5 h-5 mx-auto" />
      </MenuButton>
      <MenuList>
        <MenuGroup title="Contact has no channels.">
          <MenuItem
            onClick={() =>
              navigate(contact ? `/contacts/${contact.id}` : "/new-message")
            }
            icon={<PlusIcon className="w-4 h-4" />}
          >
            <Text>
              {contact
                ? "Add channel"
                : "The contacts selected have no channels to connect with."}
            </Text>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
