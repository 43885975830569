import { gql } from "@apollo/client";

export const GET_TEMPLATES_AND_KEYWORDS = gql`
  query GetTemplatesAndKeywords {
    keywords {
      id
      key
      value
      updatedAt
      createdAt
    }
    templates {
      id
      createdAt
      updatedAt
      name
      channel
      body
      subject
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($template: TemplateInputType) {
    createTemplate(template: $template) {
      name
      channel
      body
      subject
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate(
    $updateTemplateId: ID!
    $template: TemplateInputType
  ) {
    updateTemplate(id: $updateTemplateId, template: $template) {
      id
      createdAt
      updatedAt
      name
      channel
      body
      subject
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($deleteTemplateId: ID!) {
    deleteTemplate(id: $deleteTemplateId) {
      id
      updatedAt
      createdAt
      name
      channel
      body
      subject
    }
  }
`;
