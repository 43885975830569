import {
  Button,
  Card,
  CardBody,
  IconButton,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import { CheckIcon, ClipboardDocumentIcon } from "@heroicons/react/24/solid";

export function ScriptCopy({ completeStep }: any) {
  const { onCopy, value, setValue, hasCopied } = useClipboard(
    '<script defer type="module" src="https://cdn.jsdelivr.net/gh/SmartyrBiz/Inbox-Embeds@main/dist/index.js"></script><link href="https://cdn.jsdelivr.net/gh/SmartyrBiz/Inbox-Embeds@main/dist/index.css" rel="stylesheet" />'
  );
  return (
    <div className="flex flex-col gap-2">
      <div className="border p-4 rounded-lg relative">
        <Text mb={1}>
          {`<script
  defer
  type="module"
  src="https://cdn.jsdelivr.net/gh/SmartyrBiz/Inbox-Embeds@main/dist/index.js"
  ></script>
  `}
        </Text>
        <Text>
          {`<link
  href="https://cdn.jsdelivr.net/gh/SmartyrBiz/Inbox-Embeds@main/dist/index.css"
  rel="stylesheet"
  />`}
        </Text>
        <Tooltip placement="left" label={"Copy to clipboard"}>
          <IconButton
            aria-label="Copy to clipboard"
            variant="ghost"
            position="absolute"
            right="1"
            top="1"
            onClick={() => {
              onCopy();
              completeStep(0);
            }}
          >
            {hasCopied ? (
              <CheckIcon className="w-4 h-4" />
            ) : (
              <ClipboardDocumentIcon className="w-5 h-5" />
            )}
          </IconButton>
        </Tooltip>
      </div>
      <Button
        onClick={() => {
          onCopy();
          completeStep(0);
        }}
        variant={"outline"}
      >
        {hasCopied ? "Copied" : "Copy"}
      </Button>
    </div>
  );
}
