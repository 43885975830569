import { Button } from "@chakra-ui/button";
import { GoogleCredential } from "../../../types/Organisation";
import { RiGoogleLine } from "react-icons/ri";

type Props = {
  googleAuthorisationLink: any;
  googleCredential: GoogleCredential;
};

export default function GoogleAuthentication({
  googleAuthorisationLink,
  googleCredential,
}: Props) {
  if (googleAuthorisationLink)
    return (
      <Button
        as="a"
        size="sm"
        href={googleAuthorisationLink.url}
        variant={"outline"}
        leftIcon={<RiGoogleLine />}
      >
        Connect to Google
      </Button>
    );
  if (googleCredential && googleCredential.refreshTokenPresent) {
    <Button
      as="a"
      href={"https://myaccount.google.com/u/0/permissions"}
      variant={"outline"}
      leftIcon={<RiGoogleLine />}
    >
      Remove Google Authorisation
    </Button>;
  }
  return null;
}
