import { CircularProgress, Flex } from "@chakra-ui/react";

import { ContactCollection } from "../../../types/Contact";
import { GET_INBOX_CONTACTS } from "../../../apollo/inbox";
import InboxList from "./inbox_list/InboxList";
import { useDebounce } from "use-debounce";
import { useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  isOpenVar,
  multiSelectVar,
  searchVar,
  sortOptionVar,
} from "../../../store/inbox";
import ToggleContacts from "./appbar/ToggleContacts";
import InboxAppBar from "./appbar/InboxAppBar";

type QueryData = {
  contacts: ContactCollection;
};

function InboxView() {
  const sortOption = useReactiveVar(sortOptionVar);
  const search = useReactiveVar(searchVar);
  const isOpen = useReactiveVar(isOpenVar);

  const [debouncedValue] = useDebounce(search, 500);
  const { loading, data, fetchMore, refetch } = useQuery<QueryData>(
    GET_INBOX_CONTACTS,
    {
      variables: {
        filter: {
          hasInboxEvents: true,
          open: isOpen,
          search: debouncedValue,
        },
        sort: {
          field: sortOption.field,
          order: sortOption.value,
        },
        first: 20,
      },
      onCompleted(data) {},
      onError(error) {
        console.log(error);
      },
    }
  );
  // Add a useEffect hook to refetch the query when isOpen changes
  useEffect(() => {
    // Refetch the query with updated isOpen value
    refetch({
      filter: {
        hasInboxEvents: true,
        open: isOpen,
        search: debouncedValue,
      },
    });
  }, [isOpen]);

  return (
    <div className="relative border-r max-w-lg w-full flex flex-col">
      <InboxAppBar />
      {loading ? (
        <div className="h-full flex place-items-center justify-center">
          <CircularProgress isIndeterminate color="blue.400" />
        </div>
      ) : (
        <InboxList data={data} fetchMore={fetchMore} />
      )}
    </div>
  );
}

export default InboxView;
