import {
  Input,
  InputGroup,
  InputLeftElement,
  useMediaQuery,
} from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { SCREENS } from "../../constants/SCREENS";
import SettingsNav from "./navigation/SettingsNav";
import { useEffect } from "react";
import Placeholder from "src/components/Placeholder";
import { Cog6ToothIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";

export default function Index() {
  const location = useLocation();
  const isSettingsPage = Boolean(
    location.pathname.replaceAll("/", "") === "settings"
  );
  const [isDesktop] = useMediaQuery(SCREENS.DESKTOP);
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/settings" && isDesktop) {
      navigate("/settings/organisation");
    }
  }, []);

  return (
    <div className="h-full bg-white md:bg-gray-100">
      <div className="max-w-7xl mx-auto">
        <div className="flex p-3 gap-8 place-items-center mb-2">
          <h1 className="text-2xl font-bold ">Settings</h1>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <MagnifyingGlassIcon className="w-5 h-5" />
            </InputLeftElement>
            <Input placeholder="Search messages..." borderColor="gray.200" />
          </InputGroup>
        </div>
        <div className="flex gap-6 place-items-start ">
          {(location.pathname === "/settings" || isDesktop) && <SettingsNav />}
          {isSettingsPage ? (
            <div className="flex flex-col gap-2 place-items-center mx-auto py-4 text-gray-400 text-sm h-full py-16">
              <Cog6ToothIcon className="w-10 h-10 text-gray-400" />
              <p>Select a settings page</p>
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
    </div>
  );
}
