import { IconButton, Tooltip } from "@chakra-ui/react";
import { BiSelectMultiple } from "react-icons/bi";
import { multiSelectVar } from "src/store/inbox";

export default function InboxMultiSelect() {
  const multiSelect = multiSelectVar();
  function handleMultiSelect() {
    multiSelectVar({
      ...multiSelect,
      isMultiSelectActive: !multiSelect.isMultiSelectActive,
    });
  }
  return (
    <Tooltip label="Select multiple" openDelay={600}>
      <IconButton
        variant="ghost"
        onClick={handleMultiSelect}
        aria-label="Select Multiple"
        icon={<BiSelectMultiple className="w-5 h-5" />}
      />
    </Tooltip>
  );
}
