import { EnvelopeIcon } from "@heroicons/react/24/outline";

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import * as yup from "yup";

import { useApolloClient, useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "src/hooks/useAuth";
import { UPDATE_USER_EMAIL } from "src/apollo/user";
import { RiLockPasswordLine } from "react-icons/ri";

export default function UpdateEmail() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const schema = yup.object().shape({
    password: yup.string().required("Current password is required"),
    email: yup.string().email().required("New email is required"),
  });
  const { logout } = useAuth();
  const client = useApolloClient();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      email: "",
    },
  });

  type FormData = {
    password: string;
    email: string;
  };

  const [updateUserEmail, { loading }] = useMutation(UPDATE_USER_EMAIL, {
    onCompleted: (data) => {
      client.resetStore();
      logout();
    },
    onError: (error) => {
      error.graphQLErrors.forEach((error) => {
        switch (error.extensions.code) {
          case "EMAIL_TAKEN":
            setError("email", {
              type: "server",
              message: error.message,
            });
            break;
          case "INCORRECT_PASSWORD":
            setError("password", {
              type: "server",
              message: error.message,
            });
            break;
          default:
        }
      });
    },
  });

  function onSubmit(data: FormData) {
    updateUserEmail({
      variables: {
        email: data.email,
        password: data.password,
      },
    });
  }

  return (
    <>
      <Button
        aria-label="Create Contact"
        variant="outline"
        size="sm"
        onClick={onOpen}
      >
        Update Email
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Update Email
            <p className="text-xs text-gray-500">
              You will be logged out and required to log back in
            </p>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="space-y-2"
              id="update-contact"
            >
              <FormControl isInvalid={!!errors.email}>
                <FormLabel>New Email</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <EnvelopeIcon className="w-5 h-5" />
                  </InputLeftElement>
                  <Input
                    placeholder="i.e johnsmith@gmail.com"
                    type="email"
                    autoComplete="email"
                    id="email"
                    {...register("email")}
                  />
                </InputGroup>
                {!!errors.email && (
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.password}>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <RiLockPasswordLine />
                  </InputLeftElement>
                  <Input
                    placeholder="**********"
                    type="password"
                    autoComplete="password"
                    id="password"
                    {...register("password")}
                  />
                </InputGroup>
                {!!errors.password && (
                  <FormErrorMessage>
                    {errors.password?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant="solid" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              variant="solid"
              form="update-contact"
              isLoading={loading}
            >
              Save & Logout
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
