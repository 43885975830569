import * as yup from "yup";

import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { RiLockPasswordLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_PASSWORD } from "src/apollo/user";
import { useAuth } from "src/hooks/useAuth";

const schema = yup.object().shape({
  currentPassword: yup.string().required("Current Password is required"),
  newPassword: yup
    .string()
    .min(6, "Too Short")
    .max(50, "Too Long!")
    .required("A new password is required"),
});

type FormData = {
  currentPassword: string;
  newPassword: string;
};

export default function UpdatePassword() {
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { logout } = useAuth();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
    },
    resolver: yupResolver(schema),
  });

  const [updateUserPassword, { loading }] = useMutation(UPDATE_USER_PASSWORD, {
    onCompleted: (data) => {
      logout();
    },
    onError: (error) => {
      error.graphQLErrors.forEach((error) => {
        switch (error.extensions.code) {
          case "INCORRECT_PASSWORD":
            setError("currentPassword", {
              type: "server",
              message: error.message,
            });
            break;
          default:
        }
      });
    },
  });

  function onSubmit(data: FormData) {
    updateUserPassword({
      variables: {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
    });
  }

  return (
    <>
      <Button
        aria-label="Update Password"
        variant="outline"
        onClick={onOpen}
        size={"sm"}
      >
        Update Password
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Update Password
            <p className="text-xs text-gray-500">
              You will be logged out and required to log back in
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form
              id="update-password"
              className="space-y-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl isInvalid={!!errors.currentPassword}>
                <FormLabel>Current Password</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <RiLockPasswordLine />
                  </InputLeftElement>
                  <Input
                    placeholder="Current Password..."
                    type={isCurrentPasswordVisible ? "text" : "password"}
                    autoComplete="password"
                    id="currentPassword"
                    {...register("currentPassword")}
                  />
                </InputGroup>
                {!!errors.currentPassword && (
                  <FormErrorMessage>
                    {errors.currentPassword?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.newPassword}>
                <FormLabel>New Password</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <RiLockPasswordLine />
                  </InputLeftElement>
                  <Input
                    placeholder="New Password..."
                    type={isNewPasswordVisible ? "text" : "password"}
                    id="newPassword"
                    {...register("newPassword")}
                  />
                </InputGroup>
                {!!errors.newPassword && (
                  <FormErrorMessage>
                    {errors.newPassword?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              type="submit"
              form="update-password"
              isLoading={loading}
            >
              Update Password
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
