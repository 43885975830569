// MessageItem.js
import React from "react";
import ReviewMessage from "./message_items/Review";
import SMS from "./message_items/SMS";
import Email from "./message_items/Email";
import FacebookChat from "./message_items/FacebookChat";
import FacebookRecommendation from "./message_items/FacebookRecommendation";
import Feedback from "./message_items/Feedback";

function MessageItem({ contact, inboxEvent, previousInboxEvent }: any) {
  if (
    inboxEvent.direction === "RECEIVED" &&
    inboxEvent.channel === "REVIEW" &&
    "comment" in inboxEvent.properties &&
    "rating" in inboxEvent.properties
  ) {
    return <ReviewMessage contact={contact} inboxEvent={inboxEvent} />;
  }
  if (inboxEvent.channel === "SMS") {
    return (
      <SMS inboxEvent={inboxEvent} previousInboxEvent={previousInboxEvent} />
    );
  }
  if (inboxEvent.channel === "EMAIL") {
    return <Email contact={contact} inboxEvent={inboxEvent} />;
  }
  if (inboxEvent.channel === "FACEBOOK_CHAT") {
    return <FacebookChat inboxEvent={inboxEvent} />;
  }
  if (inboxEvent.channel === "FACEBOOK_RECOMMENDATION") {
    return <FacebookRecommendation inboxEvent={inboxEvent} />;
  }
  if (inboxEvent.channel === "FEEDBACK") {
    return <Feedback inboxEvent={inboxEvent} />;
  }
  return null;
}

export default MessageItem;
