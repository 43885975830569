import { Center, CircularProgress } from "@chakra-ui/react";
import { ContactEdge } from "../../../../types/Contact";

import InboxListItem from "./InboxListItem";
import InfiniteScroll from "react-infinite-scroll-component";

export default function InboxList({ data, fetchMore }: any) {
  function handleScroll() {
    const endCursor = data!.contacts.pageInfo.endCursor;
    const hasNextPage = data!.contacts.pageInfo.hasNextPage;
    if (hasNextPage)
      fetchMore({
        variables: {
          after: endCursor,
        },
        updateQuery: (previousResultData: any, { fetchMoreResult }: any) => {
          const contacts = [
            ...previousResultData.contacts.edges,
            ...fetchMoreResult.contacts.edges,
          ];
          const pageInfo = {
            ...fetchMoreResult.contacts.pageInfo,
            hasPreviousPage:
              previousResultData.contacts.pageInfo.hasPreviousPage,
            startCursor: previousResultData.contacts.pageInfo.startCursor,
          };
          fetchMoreResult.contacts.edges = contacts;
          fetchMoreResult.contacts.pageInfo = pageInfo;
          return fetchMoreResult;
        },
      });
  }
  return (
    <div className="relative h-full">
      {data && !!data.contacts.edges.length && (
        <div className="absolute px-2 inset-0 overflow-y-auto" id="inboxList">
          <InfiniteScroll
            dataLength={data.contacts.edges.length}
            next={handleScroll}
            scrollableTarget="inboxList"
            hasMore={!!data.contacts.pageInfo.hasNextPage}
            loader={
              <Center py={4}>
                <CircularProgress isIndeterminate color="blue.400" />
              </Center>
            }
          >
            {data.contacts.edges.map((contactEdge: ContactEdge) => {
              return (
                <InboxListItem
                  key={contactEdge.node.id}
                  contact={contactEdge.node}
                />
              );
            })}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}
