import {
  ChatBubbleOvalLeftIcon as ChatBubble,
  UsersIcon as UsersIconSolid,
  Cog6ToothIcon as Cog6ToothIconSolid,
} from "@heroicons/react/24/solid";
import {
  ChatBubbleOvalLeftIcon,
  Cog6ToothIcon,
  UsersIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";

import { BiPieChart } from "react-icons/bi";

export const NAVLINKS = [
  {
    path: "/inbox",
    icon: <ChatBubbleOvalLeftIcon className="w-5 h-5 " />,
    iconActive: <ChatBubble className="w-5 h-5 " />,
    label: "Messages",
  },
  {
    path: "/contacts",
    icon: <UsersIcon className="w-5 h-5 " />,
    iconActive: <UsersIconSolid className="w-5 h-5 " />,
    label: "Contacts",
  },
  {
    path: "/settings",
    icon: <Cog6ToothIcon className="w-5 h-5  " />,
    iconActive: <Cog6ToothIconSolid className="w-5 h-5  " />,
    label: "Settings",
  },
  /*   {
    path: "/analytics",
    icon: <BiPieChart className="w-5 h-5 text-gray-500 " />,
    label: "Analytics",
  },
  {
    path: "/kanban",
    icon: <ViewColumnsIcon className="w-5 h-5 text-gray-500 " />,
    label: "Project Manager",
  }, */
];
