import * as yup from "yup";

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { RiLockPasswordLine, RiUserLine } from "react-icons/ri";

import AuthLayout from "./AuthLayout";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../hooks/useAuth";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup.string().required("A password is required"),
});

type FormData = {
  email: string;
  password: string;
};

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [globalError, setGlobalError] = useState<null | string>(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  function onSubmit(values: FormData) {
    const email = values.email.toLocaleLowerCase();
    const password = values.password;

    setGlobalError(null);
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/auth/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.token && data.refreshToken) {
          login({
            token: data.token,
            refreshToken: data.refreshToken,
          });
        }
        if (data.statusCode === 404)
          setError("email", {
            type: "custom",
            message: data.message,
          });
        else {
          setGlobalError(data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setGlobalError(error.message);
        console.error(error);
      });
  }

  return (
    <AuthLayout
      title="Sign In"
      description="Don't have an account yet?"
      actions={
        <Button variant="link" size="sm" onClick={() => navigate("/sign-up")}>
          Sign up
        </Button>
      }
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>Name</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <RiUserLine />
            </InputLeftElement>
            <Input
              type="email"
              placeholder="i.e johnsmith@gmail.com"
              {...register("email")}
            />
          </InputGroup>
          {!!errors.email && (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </FormControl>
        <div>
          <FormControl isInvalid={!!errors.password}>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <RiLockPasswordLine />
              </InputLeftElement>
              <Input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="********"
                {...register("password")}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                >
                  {isPasswordVisible ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {!!errors.password && (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            )}
          </FormControl>
          {/*       <div className="flex justify-end gap-1 mt-0.5 place-items-center ">
            <p className="text-xs text-gray-800"> Forgot Password?</p>
            <Button
              variant="link"
              size="sm"
              onClick={() => navigate("/reset-password")}
            >
              Reset
            </Button>
          </div> */}
        </div>
        <Button
          type="submit"
          variant="solid"
          colorScheme="blue"
          isLoading={loading}
        >
          Login
        </Button>
      </form>
      {globalError && (
        <div className="bg-red-100 p-2 rounded-md mt-2 text-center text-sm text-red-900">
          <p>{globalError}</p>
        </div>
      )}
    </AuthLayout>
  );
}
