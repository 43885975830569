import InboxSearch from "./InboxSearch";
import NewMessageButton from "./NewMessageButton";
import InboxSort from "./InboxSort";
import InboxSelect from "./InboxSelect";
import { useReactiveVar } from "@apollo/client";
import { isOpenVar, multiSelectVar } from "src/store/inbox";
import InboxMultiSelect from "./InboxMultiSelect";
import ToggleContacts from "./ToggleContacts";

export default function InboxAppBar() {
  const isOpen = useReactiveVar(isOpenVar);
  const multiSelect = useReactiveVar(multiSelectVar);
  return (
    <div>
      <div className="border-b p-2 flex justify-between place-items-center">
        <InboxSelect />
        <NewMessageButton />
      </div>
      <div className="p-2 space-y-2">
        <div className="flex place-items-center justify-between gap-1 ">
          <InboxSearch />
          <InboxSort />
          <InboxMultiSelect />
        </div>
      </div>
      {multiSelect.isMultiSelectActive && <ToggleContacts />}
    </div>
  );
}
