import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";

import { Keyword } from "../../../types/Keyword";
import { Template } from "../../../types/Template";
import TemplateItem from "./TemplateItem";

type Props = {
  templates: Template[];
  keywords: Keyword[];
};

export default function TemplatesTable({ templates, keywords }: Props) {
  return (
    <>
      {templates.map((template: Template) => {
        return (
          <TemplateItem
            template={template}
            keywords={keywords}
            key={template.id}
          />
        );
      })}
    </>
  );
}
