export const SORT_OPTIONS = [
  {
    label: "Most Recent",
    value: "desc",
    field: "lastActivityAt",
  },
  {
    label: "Oldest",
    value: "asc",
    field: "lastActivityAt",
  },
];
