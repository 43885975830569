import { gql } from "@apollo/client";

export const IS_EMAIL_TAKEN = gql`
  mutation IsEmailTaken($email: String) {
    isEmailTaken(email: $email)
  }
`;

export const CREATE_EMAIL = gql`
  mutation CreateEmail(
    $email: String
    $name: String
    $username: String
    $password: String
    $smtpHost: String
    $smtpPort: Int
    $imapHost: String
    $imapPort: Int
  ) {
    createEmail(
      email: $email
      name: $name
      username: $username
      password: $password
      smtpHost: $smtpHost
      smtpPort: $smtpPort
      imapHost: $imapHost
      imapPort: $imapPort
    ) {
      email
      name
      username
      password
      smtpHost
      smtpPort
      imapHost
      imapPort
    }
  }
`;
export const UPDATE_EMAIL = gql`
  mutation UpdateEmail($updateEmailId: ID!, $email: String) {
    updateEmail(id: $updateEmailId, email: $email) {
      id
      email
    }
  }
`;
export const DELETE_EMAIL = gql`
  mutation DeleteEmail($deleteEmailId: ID!) {
    deleteEmail(id: $deleteEmailId) {
      email
    }
  }
`;
