import React from "react";

type Props = {
  action: any;
  description: string;
  title: string;
};

export default function SettingsRow({ action, description, title }: Props) {
  return (
    <div className="grid md:grid-cols-2 gap-4 py-4 items-center">
      <div className="max-w-xs space-y-0.5">
        <h3 className=" font-medium">{title}</h3>
        <p className="text-sm text-gray-500">{description}</p>
      </div>
      <div>{action}</div>
    </div>
  );
}
