import {
  GET_CONTACT_INBOX_EVENTS,
  SEND_FACEBOOK_RECOMMENDATION,
} from "../../../../apollo/messaging";
import {
  contactStatesVar,
  inputStatesVar,
  isMessageSendingVar,
  mediaUrlsVar,
} from "../../../../store/messaging";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";

import { Contact } from "../../../../types/Contact";
import { GET_USER_DETAILS } from "../../../../apollo/user";
import { useToast } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

export default function useFacebookRecommendation(
  contact: Contact | undefined,
  contactIds: Contact[] | undefined
) {
  const contactStates = useReactiveVar(contactStatesVar);
  const inputStates = useReactiveVar(inputStatesVar);
  const facebookRecommendationRef = useRef(inputStates.facebookRecommendation);
  const toast = useToast();
  const { data: userData } = useQuery(GET_USER_DETAILS);
  const [
    sendFacebookRecommendation,
    {
      data: facebookRecommendationData,
      loading: facebookRecommendationLoading,
      error: facebookRecommendationError,
    },
  ] = useMutation(SEND_FACEBOOK_RECOMMENDATION, {
    refetchQueries: () => {
      if (contact)
        return [
          {
            query: GET_CONTACT_INBOX_EVENTS,
            variables: {
              contactId: contact.id,
            },
          },
          "GetContactInboxEvents",
        ];
      return [];
    },
    onError(error) {
      isMessageSendingVar(false);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
    onCompleted(data) {
      isMessageSendingVar(false);
      if (contact) {
        contactStatesVar({
          ...contactStates,
          [contact.id]: {
            ...contactStates[contact.id],
            facebookRecommendation: "",
          },
        });
      }
    },
  });

  useEffect(() => {
    facebookRecommendationRef.current = inputStates.facebookRecommendation;
  }, [inputStates.facebookRecommendation]);

  function handleSubmitFacebookRecommendation() {
    isMessageSendingVar(true);
    sendFacebookRecommendation({
      variables: {
        message: {
          reply: facebookRecommendationRef.current!,
          contactId: [contact!.id],
        },
      },
    });
  }

  return {
    handleSubmitFacebookRecommendation,
    facebookRecommendationLoading,
    facebookRecommendationError,
    facebookRecommendationData,
  };
}
