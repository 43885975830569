import { Outlet, useLocation } from "react-router-dom";

import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import InboxView from "./inbox_view/InboxView";
import Placeholder from "../../components/Placeholder";
import React from "react";
import { SCREENS } from "../../constants/SCREENS";
import { useMediaQuery } from "@chakra-ui/react";

export default function InboxPage() {
  const location = useLocation();
  const [isDesktop] = useMediaQuery(SCREENS.DESKTOP);
  const isInbox = Boolean(location.pathname.replaceAll("/", "") === "inbox");

  return (
    <div className="flex w-full h-full">
      {(isInbox || isDesktop) && <InboxView />}
      {!isInbox ? (
        <Outlet />
      ) : (
        <Placeholder
          icon={
            <ChatBubbleBottomCenterTextIcon className="w-10 h-10 text-gray-400" />
          }
          text="Select a contact to start a conversation"
        />
      )}
    </div>
  );
}
