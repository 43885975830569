import { gql } from "@apollo/client";

export const TOGGLE_CONTACT = gql`
  mutation ToggleContact($toggleOpenContactId: ID!) {
    toggleOpenContact(id: $toggleOpenContactId) {
      id
      name
      open
    }
  }
`;
export const CLOSE_MULTIPLE_CONTACTS = gql`
  mutation Mutation($selectedContacts: [ID]!, $open: Boolean) {
    closeMultipleContacts(selectedContacts: $selectedContacts, open: $open) {
      contacts {
        id
        name
        open
      }
    }
  }
`;

export const SET_CONTACT_VIEWED = gql`
  mutation Mutation($markInboxEventViewedId: ID!) {
    markInboxEventViewed(id: $markInboxEventViewedId) {
      id
    }
  }
`;

export const GET_INBOX_CONTACTS = gql`
  query GetInboxContacts(
    $filter: ContactFilterInput
    $sort: ContactSortInput
    $after: String
    $first: Int
  ) {
    contacts(after: $after, first: $first, filter: $filter, sort: $sort) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          name
          email
          phone
          open
          googleAvatar
          inboxEvents(first: 1, sort: { field: "createdAt", order: desc }) {
            edges {
              cursor
              node {
                id
                direction
                channel
                properties
                viewed
                createdAt
                sender {
                  id
                  name
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;
