import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiBold,
  RiH1,
  RiImageAddLine,
  RiItalic,
  RiLink,
  RiListOrdered,
  RiListUnordered,
  RiStrikethrough,
  RiUnderline,
} from "react-icons/ri";

import { IconButton } from "@chakra-ui/react";
import { useCallback } from "react";
import { useCurrentEditor } from "@tiptap/react";

export default function EmailStyles({ editor }: any) {
  const setLink = useCallback(() => {
    const previousUrl = editor!.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);
    if (url === null) {
      return;
    }
    if (url === "") {
      editor!.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    editor!
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url })
      .run();
  }, [editor]);

  const addImage = () => {
    const url = window.prompt("URL");
    if (url) {
      editor!.chain().focus().setImage({ src: url }).run();
    }
  };
  if (!editor) {
    return null;
  }
  return (
    <div className="flex flex-wrap gap-1 mt-1 ">
      <IconButton
        size="sm"
        aria-label=""
        variant="ghost"
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
      >
        <RiArrowGoBackLine />
      </IconButton>
      <IconButton
        size="sm"
        aria-label=""
        variant="ghost"
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
      >
        <RiArrowGoForwardLine />
      </IconButton>
      <IconButton
        size="sm"
        aria-label=""
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        variant={editor.isActive("bold") ? "solid" : "ghost"}
      >
        <RiBold />
      </IconButton>
      <IconButton
        size="sm"
        aria-label=""
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        variant={editor.isActive("italic") ? "solid" : "ghost"}
      >
        <RiItalic />
      </IconButton>
      <IconButton
        size="sm"
        aria-label=""
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        variant={editor.isActive("underline") ? "solid" : "ghost"}
      >
        <RiUnderline />
      </IconButton>
      <IconButton
        size="sm"
        aria-label=""
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        variant={editor.isActive("strike") ? "solid" : "ghost"}
      >
        <RiStrikethrough />
      </IconButton>
      <IconButton
        size="sm"
        aria-label=""
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        variant={editor.isActive("heading", { level: 1 }) ? "solid" : "ghost"}
      >
        <RiH1 />
      </IconButton>
      <IconButton
        size="sm"
        aria-label=""
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        variant={editor.isActive("bulletList") ? "solid" : "ghost"}
      >
        <RiListUnordered />
      </IconButton>
      <IconButton
        size="sm"
        aria-label=""
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        variant={editor.isActive("orderedList") ? "solid" : "ghost"}
      >
        <RiListOrdered />
      </IconButton>
      <IconButton
        size="sm"
        aria-label=""
        onClick={setLink}
        variant={editor.isActive("link") ? "solid" : "ghost"}
      >
        <RiLink />
      </IconButton>
      <IconButton variant="ghost" size="sm" aria-label="" onClick={addImage}>
        <RiImageAddLine />
      </IconButton>
    </div>
  );
}
