import { Outlet, useLocation } from "react-router-dom";

import ContactsView from "./contacts_view/ContactsView";
import Placeholder from "../../components/Placeholder";
import { SCREENS } from "../../constants/SCREENS";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useMediaQuery } from "@chakra-ui/react";

export default function ContactsPage() {
  const [isDesktop] = useMediaQuery(SCREENS.DESKTOP);
  const location = useLocation();
  const isContactsPage = Boolean(
    location.pathname.replaceAll("/", "") === "contacts"
  );

  return (
    <div className="flex w-full h-full">
      {(isContactsPage || isDesktop) && <ContactsView />}

      {!isContactsPage ? (
        <Outlet />
      ) : (
        <Placeholder
          icon={<UserCircleIcon className="w-10 h-10 text-gray-400" />}
          text="No contact selected"
        />
      )}
    </div>
  );
}
