import { Button, IconButton, Tooltip } from "@chakra-ui/react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function NewMessageButton() {
  const navigate = useNavigate();
  return (
    <Tooltip label="New message" openDelay={600}>
      <IconButton
        variant="ghost"
        onClick={() => navigate("new-message")}
        aria-label="Create new message"
      >
        <PencilSquareIcon className="w-5 h-5" />
      </IconButton>
    </Tooltip>
  );
}
