import { Contact } from "../types/Contact";

export default function getContactName(contact: Contact) {
  const { name, phone, email } = contact;

  if (name) {
    return name;
  }
  if (phone) {
    return phone;
  }
  if (email) {
    return email;
  } else return "New Contact";
}
