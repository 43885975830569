import { useQuery } from "@apollo/client";
import FacebookAuthentication from "./FacebookAuthentication";
import SettingsLayout from "../SettingsLayout";
import SettingsRow from "../SettingsRow";
import { GET_ORGANISATION } from "src/apollo/organisation";
import GoogleAuthentication from "./GoogleAuthentication";
import { Center, CircularProgress } from "@chakra-ui/react";
import EmailAuthentication from "./EmailAuthentication";

export default function IntegrationsView() {
  const { data, loading, error, refetch } = useQuery(GET_ORGANISATION);

  if (loading) {
    return (
      <Center w="100%">
        <CircularProgress isIndeterminate color="blue.400" />
      </Center>
    );
  }

  if (error) {
    return null;
  }

  return (
    <SettingsLayout
      name="Integrations"
      description="Connect external services to Smartyr"
    >
      <SettingsRow
        title="Connect your Email"
        description="Authorise your business email to receive incoming emails, and send outgoing emails"
        action={
          <EmailAuthentication emails={data?.emails} refetchEmails={refetch} />
        }
      />
      <SettingsRow
        title="Connect to Google"
        description="Authorise your google account for reviews and messages"
        action={
          <GoogleAuthentication
            googleCredential={data?.googleCredential}
            googleAuthorisationLink={data?.googleAuthorisationLink}
          />
        }
      />
      {/* <SettingsRow
        title="Connect to Facebook"
        description="Authorise your facebook account to receive page messages"
        action={
          <FacebookAuthentication
            facebookAuthorisationLink={data?.facebookAuthorisationLink}
            facebookPages={data?.facebookPages}
            refetchPages={refetch}
          />
        }
      /> */}
    </SettingsLayout>
  );
}
