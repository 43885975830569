import { useMutation } from "@apollo/client";
import { Button, useToast } from "@chakra-ui/react";
import { RiMailLine } from "react-icons/ri";
import { DISCONNECT_EMAIL } from "src/apollo/organisation";
// import { RiEmailLine } from "react-icons/ri";
// import { DISCONNECT_EMAIL_PAGE } from "src/apollo/organisation";
import { GET_USER_DETAILS } from "src/apollo/user";
import EmailModal from "./EmailModal";
import { useState } from "react";

type Props = {
  emails: any[];
  refetchEmails: () => void;
};

export default function EmailAuthentication({ emails, refetchEmails }: Props) {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [
    disconnectEmail,
    { data: emailData, loading: emailLoading, error: emailError },
  ] = useMutation(DISCONNECT_EMAIL, {
    refetchQueries: [{ query: GET_USER_DETAILS }, "GetUserDetails"],
    onError(error) {
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
    onCompleted(data) {
      toast({
        title: "Email disconnected",
        status: "success",
      });
    },
  });

  if (!emails.length) {
    return (
      <>
        <Button
          size="sm"
          variant={"outline"}
          leftIcon={<RiMailLine />}
          onClick={() => setIsOpen(true)}
        >
          Connect your Email
        </Button>
        <EmailModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </>
    );
  } else {
    return (
      <div className="flex flex-col justify-center gap-2">
        {emails?.map((email) => {
          return (
            <div
              key={email.id}
              className="flex justify-between items-center gap-2"
            >
              <Button
                size="sm"
                variant="outline"
                className="flex w-full items-center ml-0 justify-start gap-2 "
              >
                {/* <img
                  src={page.pagePicture}
                  alt={page.pageName}
                  className="rounded-full w-6 h-6"
                /> */}

                <p className="text-sm text-gray-500">{email.email}</p>
              </Button>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  disconnectEmail({
                    variables: {
                      emailId: email.id,
                    },
                  });
                }}
              >
                <Button type="submit" size="sm" variant="outline">
                  Disconnect
                </Button>
              </form>
            </div>
          );
        })}
      </div>
    );
  }
}
