import { EditorContent, useEditor } from "@tiptap/react";
import { Extension } from "@tiptap/core";
import {
  contactStatesVar,
  inputStatesVar,
  isMessageSendingVar,
} from "../../../../store/messaging";

import { Contact } from "../../../../types/Contact";
import EmailFields from "./email/EmailFields";
import Image from "@tiptap/extension-image";
import InputActions from "./input_actions/InputActions";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import SMSAttachment from "./sms/SMSAttachment";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { useReactiveVar } from "@apollo/client";
import NoChannels from "./NoChannels";

type Props = {
  contact?: Contact;
  contacts?: Contact[];
  channels: string[];
};
const channelsMap = {
  SMS: {
    placeholder: "",
    name: "SMS",
    key: "SMSBody",
  },
  EMAIL: {
    placeholder: "Email Body",
    name: "Email",
    key: "emailBody",
  },
  REVIEW: {
    placeholder: "",
    name: "Review",
    key: "reviewReply",
  },
  FACEBOOK_CHAT: {
    placeholder: "",
    name: "Facebook Chat",
    key: "facebookChat",
  },
  FACEBOOK_RECOMMENDATION: {
    placeholder: "",
    name: "Facebook Recommendation",
    key: "facebookRecommendation",
  },
};

export default function InputBar({ contact, contacts, channels }: Props) {
  const inputStates = useReactiveVar(inputStatesVar);
  const contactStates = useReactiveVar(contactStatesVar);
  const isMessageSending = useReactiveVar(isMessageSendingVar);

  const extensions = [
    Underline,
    Image,
    Link.configure({
      openOnClick: false,
      HTMLAttributes: {
        class: "text-blue-500 underline",
      },
    }),
    Placeholder.configure({
      placeholder: inputStates.selectedChannel === "EMAIL" ? "Body" : "",
    }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false,
        HTMLAttributes: {
          class: "list-disc pl-4",
        },
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false,
        HTMLAttributes: {
          class: "list-decimal pl-4",
        },
      },
    }),
  ];
  const content = getContent();

  function getContent() {
    switch (inputStates.selectedChannel) {
      case "EMAIL":
        return contactStates[contact!.id].emailBody!;
      case "SMS":
        return contactStates[contact!.id].SMSBody!;
      case "FACEBOOK_CHAT":
        return contactStates[contact!.id].facebookChat!;
      case "FACEBOOK_RECOMMENDATION":
        return contactStates[contact!.id].facebookRecommendation!;
      default:
        return "";
    }
  }

  const DisableEnter = Extension.create({
    addKeyboardShortcuts() {
      return {
        Enter: [
          "iPhone",
          "iPad",
          "iPod",
          "Android",
          "BlackBerry",
          "Windows Phone",
        ].includes(navigator.platform)
          ? () =>
              this.editor.commands.first(({ commands }) => [
                () => commands.newlineInCode(),
                () => commands.splitListItem("listItem"),
                () => commands.createParagraphNear(),
                () => commands.liftEmptyBlock(),
                () => commands.splitBlock(),
              ])
          : () => true,
        "Shift-Enter": () =>
          this.editor.commands.first(({ commands }) => [
            () => commands.newlineInCode(),
            () => commands.splitListItem("listItem"),
            () => commands.createParagraphNear(),
            () => commands.liftEmptyBlock(),
            () => commands.splitBlock(),
          ]),
      };
    },
  });

  const editor = useEditor(
    {
      extensions: [DisableEnter, ...extensions],
      content,
      onFocus: (props) => {
        inputStatesVar({
          ...inputStates,
          inputFocus: true,
        });
      },
      onBlur: () => {
        inputStatesVar({
          ...inputStates,
          inputFocus: false,
        });
      },
      onUpdate() {
        if (contact) {
          inputStatesVar({
            ...inputStates,
            [channelsMap[
              inputStates.selectedChannel as keyof typeof channelsMap
            ].key]:
              inputStates.selectedChannel === "EMAIL"
                ? editor!.getHTML()
                : editor!.getText(),
          });

          contactStatesVar({
            ...contactStates,
            [contact.id]: {
              ...contactStates[contact.id],
              [channelsMap[
                inputStates.selectedChannel as keyof typeof channelsMap
              ].key]:
                inputStates.selectedChannel === "EMAIL"
                  ? editor!.getHTML()
                  : editor!.getText(),
            },
          });
        }
      },
    },
    [contact, inputStates.selectedChannel, isMessageSending]
  );

  return (
    <div className="px-2 pb-2">
      {inputStates.selectedChannel ? (
        <div className="border rounded-lg gap-0.5 place-items-end w-full p-1 shadow-sm ">
          {inputStates.selectedChannel === "EMAIL" && (
            <EmailFields editor={editor} />
          )}
          {inputStates.selectedChannel === "SMS" && <SMSAttachment />}
          <EditorContent editor={editor} />
          <InputActions
            contact={contact}
            contacts={contacts}
            channels={channels}
            editor={editor}
          />
        </div>
      ) : (
        <NoChannels contact={contact} />
      )}
    </div>
  );
}
