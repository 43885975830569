import * as yup from "yup";

import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { GET_INVITES, INVITE_USER } from "../../../apollo/users";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserPlusIcon } from "@heroicons/react/24/outline";

const schema = yup.object().shape({
  email: yup.string().email().required("Current Password is required"),
});

type FormData = {
  email: string;
};

export default function InviteUser() {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const [inviteUser, { loading }] = useMutation(INVITE_USER, {
    refetchQueries: [{ query: GET_INVITES }, "GetInvites"],
    onCompleted: (data) => {
      toast({
        title: `Invite sent`,
        status: "success",
      });
      reset();
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: `Error occurred`,
        description: error.message,
        status: "error",
      });
    },
  });

  function onSubmit(data: FormData) {
    inviteUser({
      variables: {
        email: data.email,
      },
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex gap-2 max-w-md">
      <FormControl isInvalid={!!errors.email}>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <UserPlusIcon className="h-5 w-5" />
          </InputLeftElement>

          <Input
            placeholder="johnsmith@gmail.com"
            type="email"
            {...register("email")}
          />
        </InputGroup>
        {!!errors.email && (
          <FormErrorMessage>{errors.email.message}</FormErrorMessage>
        )}
      </FormControl>
      <Button
        aria-label="Send Invite"
        flexShrink={0}
        colorScheme="blue"
        type="submit"
        isLoading={loading}
      >
        Send Invite
      </Button>
    </form>
  );
}
