import { Avatar, Button, IconButton } from "@chakra-ui/react";
import { PencilIcon, StarIcon } from "@heroicons/react/24/solid";
import {
  contactStatesVar,
  inputStatesVar,
  reviewDataVar,
} from "../../../../../store/messaging";
import { useEffect, useState } from "react";

import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { Contact } from "../../../../../types/Contact";
import { InboxEvent } from "../../../../../types/InboxEvent";
import { RATINGS } from "../../../../../constants/RATINGS";
import { dateFormatter } from "../../../../../utilities/dateFormatter";
import { useReactiveVar } from "@apollo/client";

type Props = {
  contact: Contact;
  inboxEvent: InboxEvent;
};

export default function ReviewMessage({ contact, inboxEvent }: Props) {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [isReplyDetailsVisible, setIsReplyDetailsVisible] = useState(false);
  const messageInputState = useReactiveVar(inputStatesVar);
  const contactStates = useReactiveVar(contactStatesVar);

  const { id, createdAt } = inboxEvent;

  const { name, rating, comment, reply, replyUpdatedAt }: any =
    inboxEvent.properties;

  function handleUpdateReply() {
    inputStatesVar({
      ...messageInputState,
      reviewReply: reply,
      selectedChannel: "REVIEW",
    });
    contactStatesVar({
      ...contactStates,
      [contact.id]: {
        ...contactStates[contact.id],
        reviewReply: reply,
        selectedChannel: "REVIEW",
      },
    });
  }

  function handleReviewReply() {
    inputStatesVar({ ...messageInputState, selectedChannel: "REVIEW" });
    contactStatesVar({
      ...contactStates,
      [contact.id]: {
        ...contactStates[contact.id],
        selectedChannel: "REVIEW",
      },
    });
  }

  //If Review Message is found then will store the message id and review url.
  //will not work if review message is not one of the fetched messages.
  //Probably need to store the review url and message id on the backend because
  //I dont envision a contact having more than one google review

  useEffect(() => {
    reviewDataVar({ contact_google_url: name, message_id: id });
  }, []);

  return (
    <>
      <div className="flex gap-2 justify-start max-w-[95%] md:max-w-[80%]">
        <Avatar size="sm" name={contact.name} src={contact.googleAvatar} />
        <div className="flex flex-col">
          <div
            className="p-3 bg-gray-100 rounded-xl"
            onClick={() => setIsDetailsVisible(!isDetailsVisible)}
          >
            <div className="flex justify-between mb-3 place-items-center">
              <div>
                <p className="font-medium">Google Review</p>
                <div className="flex gap-1 text-sm text-gray-600 place-items-center">
                  <p>{RATINGS[rating as keyof typeof RATINGS]}/5</p>
                  <div className="flex">
                    {new Array(RATINGS[rating as keyof typeof RATINGS])
                      .fill(null)
                      .map((index) => (
                        <StarIcon
                          className="w-5 h-5 text-yellow-500"
                          key={Math.random()}
                        />
                      ))}
                  </div>
                </div>
              </div>
              {!reply && (
                <IconButton
                  variant="ghost"
                  aria-label="Reply to review"
                  onClick={() => handleReviewReply()}
                >
                  <ArrowUturnLeftIcon className="w-4 h-4" />
                </IconButton>
              )}
            </div>
            <p>{comment}</p>
          </div>
          {isDetailsVisible && (
            <p className="mt-px text-sm text-gray-500">
              {dateFormatter(createdAt)}
            </p>
          )}
        </div>
      </div>
      {reply && (
        <div className="flex gap-2 mt-3 max-w-[95%] md:max-w-[80%] ml-auto justify-end">
          <div className="flex flex-col place-items-end">
            <div
              className="p-3  bg-blue-100 rounded-xl"
              onClick={() => setIsReplyDetailsVisible(!isReplyDetailsVisible)}
            >
              <div className="flex justify-between mb-2 place-items-center">
                <div className="flex gap-2 place-items-center">
                  <ArrowUturnLeftIcon className="w-4 h-4" />
                  <p className="font-medium">
                    Reply to {contact.name} Google Review
                  </p>
                </div>
              </div>
              <p>{reply}</p>
            </div>
            {isReplyDetailsVisible && (
              <p className="mt-px text-sm text-gray-500">
                {dateFormatter(replyUpdatedAt)}
              </p>
            )}
            <Button
              leftIcon={<PencilIcon className="w-3 h-3" />}
              onClick={() => handleUpdateReply()}
              size="sm"
              variant="ghost"
            >
              Edit Response
            </Button>
          </div>
          {"name" in inboxEvent.properties && (
            <Avatar size="sm" name={inboxEvent.properties.name} src={""} />
          )}
        </div>
      )}
    </>
  );
}
