import InviteUsers from "./InviteUser";
import SettingsLayout from "../SettingsLayout";
import UsersTable from "./UsersTable";

export default function UsersPage() {
  return (
    <SettingsLayout name={"Users"} description="Manage Smartyr users">
      <div className="space-y-4">
        <InviteUsers />
        <UsersTable />
      </div>
    </SettingsLayout>
  );
}
