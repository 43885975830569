import { useState } from "react";
import { dateFormatter } from "../../../../../utilities/dateFormatter";
import { InboxEvent } from "src/types/InboxEvent";
import { Avatar } from "@chakra-ui/react";

type Props = {
  inboxEvent: InboxEvent;
};

export default function FacebookChat({ inboxEvent }: Props) {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const { createdAt, direction, properties, contact } = inboxEvent;

  if ("chat" in properties && direction === "RECEIVED") {
    return (
      <div className="flex gap-2  justify-start max-w-[95%] md:max-w-[80%]">
        <Avatar size="sm" {...contact} />
        <div className="flex flex-col place-items-start mb-3">
          <div
            className=" bg-gray-100 p-3 rounded-tl-md rounded-xl"
            onClick={() => setIsDetailsVisible(!isDetailsVisible)}
          >
            <p className="paragraph-regular">{properties.chat}</p>
          </div>
          {isDetailsVisible && (
            <p className="text-sm text-gray-600 mt-px">
              {dateFormatter(createdAt)}
            </p>
          )}
        </div>
      </div>
    );
  }

  if ("attachments" in properties && direction === "RECEIVED") {
    return (
      <div className="flex max-w-[95%] md:max-w-[80%] mr-auto justify-start gap-2 mb-2">
        <Avatar size="sm" {...contact} />
        <div className="flex place-items-start w-full flex-col">
          {properties.attachments && (
            <>
              {properties.attachments.map((attachment, index) => {
                const { type, key } = attachment;

                switch (type) {
                  case "image":
                    return (
                      <img
                        key={index}
                        alt=""
                        src={`${process.env.REACT_APP_API_URL}/api/v1/s3?file=${key}`}
                        className="rounded-xl w-full xl:w-1/2"
                      />
                    );
                  case "video":
                    return (
                      <video
                        key={index}
                        src={`${process.env.REACT_APP_API_URL}/api/v1/s3?file=${key}`}
                        controls
                        className="rounded-xl w-full xl:w-1/2"
                      />
                    );

                  default:
                    return (
                      <div
                        key={index}
                        className=" bg-gray-100 p-3 rounded-tl-md rounded-xl"
                        onClick={() => setIsDetailsVisible(!isDetailsVisible)}
                      >
                        <p className="paragraph-regular">
                          Error rendering this media, please contact the Smartyr
                          Team
                        </p>
                      </div>
                    );
                }
              })}
            </>
          )}

          {isDetailsVisible && (
            <p className="text-sm text-gray-600 mt-px">
              {dateFormatter(createdAt)}
            </p>
          )}
        </div>
      </div>
    );
  }

  if ("chat" in properties && direction === "SENT")
    return (
      <div className="flex max-w-[95%] md:max-w-[80%] ml-auto justify-end gap-2 mb-2 ">
        <div className="flex place-items-end w-full flex-col">
          <div
            className=" bg-blue-500 p-3 rounded-tr-md rounded-xl"
            onClick={() => setIsDetailsVisible(!isDetailsVisible)}
          >
            <p className="paragraph-regular text-white">{properties.chat}</p>
          </div>
        </div>
        <Avatar size="sm" {...inboxEvent.sender} />
      </div>
    );

  if ("attachments" in properties && direction === "SENT") {
    return (
      <div className="flex max-w-[95%] md:max-w-[80%] ml-auto justify-end gap-2 mb-2 ">
        <div className="flex place-items-end w-full flex-col">
          {properties.attachments && (
            <>
              {properties.attachments.map((attachment, index) => {
                const { type, key } = attachment;

                switch (type) {
                  case "image":
                    return (
                      <img
                        key={index}
                        alt=""
                        src={`${process.env.REACT_APP_API_URL}/api/v1/s3?file=${key}`}
                        className="rounded-xl w-full xl:w-1/2"
                      />
                    );
                  case "video":
                    return (
                      <video
                        key={index}
                        src={`${process.env.REACT_APP_API_URL}/api/v1/s3?file=${key}`}
                        controls
                        className="rounded-xl w-full xl:w-1/2"
                      />
                    );

                  default:
                    return (
                      <div
                        key={index}
                        className=" bg-gray-100 p-3 rounded-tl-md rounded-xl"
                        onClick={() => setIsDetailsVisible(!isDetailsVisible)}
                      >
                        <p className="paragraph-regular">
                          Error rendering this media, please contact the Smartyr
                          Team
                        </p>
                      </div>
                    );
                }
              })}
            </>
          )}

          {isDetailsVisible && (
            <p className="text-sm text-gray-600 mt-px">
              {dateFormatter(createdAt)}
            </p>
          )}
        </div>
        <Avatar size="sm" {...inboxEvent.sender} />
      </div>
    );
  }

  return null;
}
