import * as yup from "yup";

import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { EnvelopeIcon, PhoneIcon, UserIcon } from "@heroicons/react/24/outline";
import { GET_CONTACT_DETAILS, UPDATE_CONTACT } from "../../../apollo/contact";

import { Contact } from "../../../types/Contact";
import DeleteContact from "../contacts_view/table/DeleteContact";
import { GET_CONTACT_INBOX_EVENTS } from "../../../apollo/messaging";
import { phone } from "phone";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object().shape({
  name: yup.string().max(50, "Too Long!"),
  phone: yup
    .string()
    .test("isPhoneValid", "Invalid phone number", (value) =>
      value != undefined && value.length
        ? phone(value, { country: "AU" }).isValid
        : true
    ),
  email: yup.string().email("Invalid email"),
});

type Props = {
  contact: Contact;
};

type FormData = {
  name: string;
  email: string;
  phone: string;
};
export default function ContactForm({ contact }: Props) {
  const { contactId } = useParams();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [updateContact, { loading: updateLoading }] = useMutation(
    UPDATE_CONTACT,
    {
      refetchQueries: [
        {
          query: GET_CONTACT_DETAILS,
          variables: {
            contactId: contactId,
          },
        },
        "GetContactDetails",
        {
          query: GET_CONTACT_INBOX_EVENTS,
          variables: {
            contactId: contactId,
          },
        },
        "GetContactDetails",
      ],
      onError: (error) => {
        if (error.graphQLErrors) {
          error.graphQLErrors.forEach((error) => {
            switch (error.extensions.errors) {
              case "phone":
                setError("phone", {
                  type: "server",
                  message: error.message,
                });
                break;
              case "email":
                setError("email", {
                  type: "server",
                  message: error.message,
                });
                break;
              default:
                toast({
                  title: "Error occured",
                  description: error.message,
                  status: "error",
                });
                console.log(error);
            }
          });
        }
      },
    }
  );

  useEffect(() => {
    if (contact) {
      setValue("name", contact.name ? contact.name : "");
      setValue("email", contact.email ? contact.email : "");
      setValue("phone", contact.phone ? contact.phone : "");
    }
  }, [contact, contactId]);

  function onSubmit(values: FormData) {
    const formattedValues = {
      name: values.name ? values.name : null,
      email: values.email ? values.email : null,
      phone: values.phone ? values.phone : null,
    };
    if (contact) {
      const variablesObject = new Object();
      if (formattedValues.name !== contact.name) {
        Object.assign(variablesObject, { name: formattedValues.name });
      }

      if (formattedValues.email !== contact.email) {
        Object.assign(variablesObject, {
          email: formattedValues.email,
        });
      }

      if (formattedValues.phone !== contact.phone) {
        Object.assign(variablesObject, {
          phone: formattedValues.phone,
        });
      }

      if (Object.keys(variablesObject).length > 0) {
        updateContact({
          variables: {
            updateContactId: contact.id,
            contact: variablesObject,
          },
          onCompleted() {
            toast({
              title: "Contact saved",
              status: "success",
            });
          },
        });
      }
    }
  }
  if (contact)
    return (
      <form
        id="update-contact-form"
        className=" space-y-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl isInvalid={!!errors.name}>
          <FormLabel>Name</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <UserIcon className="w-5 h-5" />
            </InputLeftElement>
            <Input
              placeholder="i.e John Smith"
              id="name"
              {...register("name")}
            />
          </InputGroup>
          {errors.name && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>Email</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <EnvelopeIcon className="w-5 h-5" />
            </InputLeftElement>
            <Input
              placeholder="i.e johnsmith@gmail.com"
              id="email"
              {...register("email")}
            />
          </InputGroup>
          {errors.email && (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!errors.phone}>
          <FormLabel>Phone</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <PhoneIcon className="w-5 h-5" />
            </InputLeftElement>
            <Input
              placeholder="i.e 0433 701 1042"
              id="phone"
              {...register("phone")}
            />
          </InputGroup>
          {errors.phone && (
            <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
          )}
        </FormControl>
        <div className="flex gap-2">
          <Button
            type="submit"
            variant="solid"
            colorScheme={isDirty ? "blue" : "gray"}
            isLoading={updateLoading}
          >
            Save
          </Button>
          <DeleteContact contact={contact} />
        </div>
      </form>
    );
  return null;
}
