import ContactsAppBar from "../../contacts/contacts_view/appbar/ContactsAppBar";
import ContactsTable from "../../contacts/contacts_view/table/ContactsTable";
import { FILTER_OPTIONS } from "../../../constants/FILTER_OPTIONS";
import { SORT_OPTIONS } from "../../../constants/SORT_OPTIONS";
import { useState } from "react";
import { Button } from "@chakra-ui/react";

export default function NewMessageView() {
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);
  const [filterOption, setFilterOption] = useState(FILTER_OPTIONS[0]);
  const [search, setSearch] = useState("");

  return (
    <div className="flex flex-col w-full h-screen col-span-2">
      <ContactsAppBar
        sortOption={sortOption}
        setSortOption={setSortOption}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        search={search}
        setSearch={setSearch}
      />
      <ContactsTable isInbox={true} />
    </div>
  );
}
