import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { searchVar } from "src/store/inbox";
import { useReactiveVar } from "@apollo/client";

export default function InboxSearch() {
  const search = useReactiveVar(searchVar);
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <MagnifyingGlassIcon className="w-5 h-5" />
      </InputLeftElement>
      <Input
        placeholder="Search messages..."
        value={search}
        borderColor="gray.200"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          searchVar(e.target.value)
        }
      />
    </InputGroup>
  );
}
