import {
  GET_CONTACT_INBOX_EVENTS,
  SEND_FACEBOOK_CHAT,
  SEND_TEXT_MESSAGE,
} from "../../../../apollo/messaging";
import {
  attachmentStateVar,
  contactStatesVar,
  inputStatesVar,
  isMessageSendingVar,
  mediaUrlsVar,
} from "../../../../store/messaging";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";

import { Contact } from "../../../../types/Contact";
import { useToast } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

export default function useFacebookChat(
  contact: Contact | undefined,
  contactIds: Contact[] | undefined
) {
  const contactStates = useReactiveVar(contactStatesVar);
  const inputStates = useReactiveVar(inputStatesVar);
  const attachmentState = useReactiveVar(attachmentStateVar);
  const facebookChatRef = useRef(inputStates.facebookChat);
  const toast = useToast();
  const [
    sendFacebookChat,
    {
      data: facebookChatData,
      loading: facebookChatLoading,
      error: facebookChatError,
    },
  ] = useMutation(SEND_FACEBOOK_CHAT, {
    refetchQueries: () => {
      if (contact)
        return [
          {
            query: GET_CONTACT_INBOX_EVENTS,
            variables: {
              contactId: contact.id,
            },
          },
          "GetContactInboxEvents",
        ];
      return [];
    },
    onError(error) {
      isMessageSendingVar(false);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
    onCompleted(data) {
      if (contact) {
        contactStatesVar({
          ...contactStates,
          [contact.id]: {
            ...contactStates[contact.id],
            facebookChat: "",
          },
        });
      }
      isMessageSendingVar(false);
      attachmentStateVar(null);
    },
  });

  useEffect(() => {
    facebookChatRef.current = inputStates.facebookChat;
  }, [inputStates.facebookChat]);

  const getMessageObject = () => {
    if (attachmentState) {
      return {
        chat: facebookChatRef.current,
        contactId: [contact!.id],
        attachments: [attachmentState],
      };
    }

    return {
      chat: facebookChatRef.current,
      contactId: [contact!.id],
    };
  };

  function handleSubmitFacebookChat() {
    if (!facebookChatRef.current && !attachmentState) return;
    isMessageSendingVar(true);

    sendFacebookChat({
      variables: {
        message: getMessageObject(),
      },
    });
  }

  return {
    handleSubmitFacebookChat,
    facebookChatLoading,
    facebookChatError,
    facebookChatData,
  };
}
