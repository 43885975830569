import { SCREENS } from "../constants/SCREENS";
import { useMediaQuery } from "@chakra-ui/react";

type Props = {
  icon?: any;
  text?: string;
  loading?: boolean;
};

export default function Placeholder({ icon, text, loading }: Props) {
  const [isDesktop] = useMediaQuery(SCREENS.DESKTOP);
  if (text && isDesktop)
    return (
      <div className="flex justify-center w-full h-full place-items-center bg-gray-50 col-span-2">
        <div className="flex flex-col gap-2 place-items-center">
          {icon}
          <p className="text-gray-400">{text}</p>
        </div>
      </div>
    );
  return null;
}
