import { InboxEventEdge } from "../../../../types/InboxEvent";

import ChannelIndicator from "./ChannelInidcator";
import { CircularProgress } from "@chakra-ui/progress";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageItem from "./MessageItem";

export default function MessageList({ fetchMore, data }: any) {
  function loadMoreInboxEvents() {
    const endCursor = data!.inboxEvents.pageInfo.endCursor;
    const hasNextPage = data!.inboxEvents.pageInfo.hasNextPage;
    if (hasNextPage)
      fetchMore({
        variables: {
          first: 5,
          after: endCursor,
        },
        updateQuery: (previousResultData: any, { fetchMoreResult }: any) => {
          const inboxEvents = [
            ...previousResultData.inboxEvents.edges,
            ...fetchMoreResult.inboxEvents.edges,
          ];
          const pageInfo = {
            ...fetchMoreResult.inboxEvents.pageInfo,
            hasPreviousPage:
              previousResultData.inboxEvents.pageInfo.hasPreviousPage,
            startCursor: previousResultData.inboxEvents.pageInfo.startCursor,
          };
          fetchMoreResult.inboxEvents.edges = inboxEvents;

          fetchMoreResult.inboxEvents.pageInfo = pageInfo;

          return fetchMoreResult;
        },
      });
  }
  return (
    <div className="relative h-full">
      <div className="absolute h-full w-full">
        <div
          id="scrollableDiv"
          className="p-3"
          style={{
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          <InfiniteScroll
            style={{
              display: "flex",
              flexDirection: "column-reverse",
            }}
            scrollableTarget="scrollableDiv"
            inverse={true}
            dataLength={data.inboxEvents.edges.length}
            next={loadMoreInboxEvents}
            hasMore={!!data?.inboxEvents?.pageInfo.hasNextPage}
            loader={
              <div className="flex w-full h-full">
                <CircularProgress
                  size="24px"
                  isIndeterminate
                  color="blue.400"
                />
              </div>
            }
            endMessage={
              data.inboxEvents.edges.length > 20 && (
                <div className="flex w-full h-full">
                  <p className="text-sm text-gray-600">Start of conversation</p>
                </div>
              )
            }
          >
            {data.inboxEvents.edges.map(
              (inboxEvent: InboxEventEdge, index: number) => {
                const previousInboxEvent = data.inboxEvents.edges[index + 1]
                  ? data.inboxEvents.edges[index + 1].node
                  : undefined;
                return (
                  <div key={inboxEvent.node.id}>
                    <ChannelIndicator
                      channel={inboxEvent.node.channel}
                      previousChannel={
                        previousInboxEvent
                          ? previousInboxEvent.channel
                          : undefined
                      }
                    />
                    <MessageItem
                      contact={data.contact}
                      inboxEvent={inboxEvent.node}
                      previousInboxEvent
                    />
                  </div>
                );
              }
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
