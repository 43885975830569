import { Avatar } from "@chakra-ui/react";
import { InboxEvent } from "../../../../../types/InboxEvent";
import { dateFormatter } from "../../../../../utilities/dateFormatter";
import { useState } from "react";

type Props = {
  inboxEvent: InboxEvent;
  previousInboxEvent: InboxEvent | undefined;
};

export default function SMS({ inboxEvent, previousInboxEvent }: Props) {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const { createdAt, id, direction, properties, contact } = inboxEvent;

  if ("message" in properties && direction === "RECEIVED") {
    return (
      <div className="flex gap-2  justify-start max-w-[95%] md:max-w-[80%]">
        <Avatar size="sm" name={contact.name} src={contact.googleAvatar} />
        <div className="flex flex-col mb-3 place-items-start">
          <div
            className="p-3 bg-gray-100 rounded-tl-md rounded-xl"
            onClick={() => setIsDetailsVisible(!isDetailsVisible)}
          >
            <p className="paragraph-regular">{properties.message}</p>
          </div>
          {isDetailsVisible && (
            <p className="mt-px text-sm text-gray-600">
              {dateFormatter(createdAt)}
            </p>
          )}
        </div>
      </div>
    );
  }

  if ("attachments" in properties && direction === "RECEIVED") {
    return (
      <div className="flex max-w-[95%] md:max-w-[80%] mr-auto justify-start gap-2 mb-2">
        <Avatar size="sm" {...contact} />
        <div className="flex place-items-start w-full flex-col">
          {properties.attachments && (
            <>
              {properties.attachments.map((attachment, index) => {
                const { type, key } = attachment;

                switch (type) {
                  case "image":
                    return (
                      <img
                        key={index}
                        alt=""
                        src={`${process.env.REACT_APP_API_URL}/api/v1/s3?file=${key}`}
                        className="rounded-xl w-full xl:w-1/2"
                      />
                    );
                  case "video":
                    return (
                      <video
                        key={index}
                        src={`${process.env.REACT_APP_API_URL}/api/v1/s3?file=${key}`}
                        controls
                        className="rounded-xl w-full xl:w-1/2"
                      />
                    );

                  default:
                    return (
                      <div
                        key={index}
                        className=" bg-gray-100 p-3 rounded-tl-md rounded-xl"
                        onClick={() => setIsDetailsVisible(!isDetailsVisible)}
                      >
                        <p className="paragraph-regular">
                          Error rendering this media, please contact the Smartyr
                          Team
                        </p>
                      </div>
                    );
                }
              })}
            </>
          )}

          {isDetailsVisible && (
            <p className="text-sm text-gray-600 mt-px">
              {dateFormatter(createdAt)}
            </p>
          )}
        </div>
      </div>
    );
  }

  if (
    "message" in properties &&
    direction === "SENT" &&
    !("experience" in properties)
  ) {
    return (
      <div className="flex max-w-[95%] md:max-w-[80%] ml-auto justify-end gap-2 mb-2 ">
        <div className="flex flex-col place-items-end">
          {/*       {properties.media.map((mediaUrl) => {
            return <img src={mediaUrl} alt="" />;
          })} */}
          <div
            className="p-3 bg-blue-100 rounded-tr-md rounded-xl"
            onClick={() => setIsDetailsVisible(!isDetailsVisible)}
          >
            <p className=" paragraph-regular">{properties.message}</p>
          </div>
          {"fromNumber" in properties && isDetailsVisible && (
            <p className="mt-px text-sm text-gray-600">
              {properties.fromNumber} - {dateFormatter(createdAt)}
            </p>
          )}
        </div>
        <Avatar
          size="sm"
          name={inboxEvent.sender ? inboxEvent.sender.name : ""}
          src={""}
        />
      </div>
    );
  }

  if ("attachments" in properties && direction === "SENT") {
    return (
      <div className="flex max-w-[95%] md:max-w-[80%] ml-auto justify-end gap-2 mb-2 ">
        <div className="flex place-items-end w-full flex-col">
          {properties.attachments && (
            <>
              {properties.attachments.map((attachment, index) => {
                const { type, key } = attachment;

                switch (type) {
                  case "image":
                    return (
                      <img
                        key={index}
                        alt=""
                        src={`${process.env.REACT_APP_API_URL}/api/v1/s3?file=${key}`}
                        className="rounded-xl w-full xl:w-1/2"
                      />
                    );
                  case "video":
                    return (
                      <video
                        key={index}
                        src={`${process.env.REACT_APP_API_URL}/api/v1/s3?file=${key}`}
                        controls
                        className="rounded-xl w-full xl:w-1/2"
                      />
                    );

                  default:
                    return (
                      <div
                        key={index}
                        className=" bg-gray-100 p-3 rounded-tl-md rounded-xl"
                        onClick={() => setIsDetailsVisible(!isDetailsVisible)}
                      >
                        <p className="paragraph-regular">
                          Error rendering this media, please contact the Smartyr
                          Team
                        </p>
                      </div>
                    );
                }
              })}
            </>
          )}

          {isDetailsVisible && (
            <p className="text-sm text-gray-600 mt-px">
              {dateFormatter(createdAt)}
            </p>
          )}
        </div>
        <Avatar size="sm" {...inboxEvent.sender} />
      </div>
    );
  }

  return null;
}
