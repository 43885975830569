import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useToast,
} from "@chakra-ui/react";

import { Contact } from "../../../../types/Contact";
import { DELETE_CONTACT } from "../../../../apollo/contact";
import { GET_CONTACTS } from "../../../../apollo/contacts";
import { TrashIcon } from "@heroicons/react/24/solid";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { GET_INBOX_CONTACTS } from "src/apollo/inbox";

export default function DeleteContact({ contact }: { contact: Contact }) {
  const navigate = useNavigate();
  const toast = useToast();
  const [deleteContact] = useMutation(DELETE_CONTACT, {
    variables: { deleteContactId: contact.id },
    onError: (error) => {
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
    onCompleted: () => {
      toast({
        title: "Contact deleted",
        status: "success",
      });

      navigate(-1);
    },
    refetchQueries: [
      { query: GET_CONTACTS },
      "GetContacts",
      { query: GET_INBOX_CONTACTS },
      "GetInboxContacts",
    ],
    awaitRefetchQueries: true,
  });

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          variant="ghost"
          colorScheme="red"
          leftIcon={<TrashIcon className="h-4 w-4" />}
        >
          Delete Contact
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Confirmation!</PopoverHeader>
        <PopoverBody>
          Are you sure you want to delete this contact? All associated data will
          be lost.
        </PopoverBody>
        <PopoverFooter>
          <Button onClick={() => deleteContact()} colorScheme="red">
            Yes
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
