import * as yup from "yup";

import {
  BuildingOfficeIcon,
  EnvelopeIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthLayout from "./AuthLayout";
import { RiLockPasswordLine } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup
    .string()
    .min(8, "Password too short")
    .max(50, "Password too long")
    .required("A password is required"),
});

type FormData = {
  email: string;
  password: string;
  organisation: string;
  name: string;
};

export default function SignUpPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const toast = useToast();
  const inviteToken = searchParams.get("i");

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      organisation: "",
      name: "",
    },
    resolver: yupResolver(schema),
  });

  function onSubmit(values: FormData) {
    setIsLoading(true);
    const path = inviteToken ? "user-signup" : "signup";
    const postValues = inviteToken
      ? { ...values, inviteToken: inviteToken }
      : values;
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/auth/${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postValues),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        if (data.success) {
          toast({
            title: "Success",
            description: "Account created",
            status: "success",
          });
          navigate("/login", { replace: true });
        } else {
          switch (data.message.statusCode) {
            case 401:
              setError("email", {
                type: "custom",
                message: "Email is already in use",
              });
              break;
            case "invite-expired":
              toast({
                title: "Error occurred",
                description: "Invite expired",
                status: "error",
              });
              break;
            default:
              toast({
                title: "Error occurred",
                description: "An unexpected error occurred",
                status: "error",
              });
          }
        }
      });
  }

  return (
    <AuthLayout
      title="Register"
      description="Already have an account?"
      actions={
        <Button
          colorScheme="blue"
          variant="link"
          onClick={() => navigate("/login")}
        >
          Login
        </Button>
      }
    >
      <form
        className="flex flex-col gap-4 mt-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        {!inviteToken && (
          <FormControl isRequired>
            <FormLabel>Organisation Name</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <BuildingOfficeIcon className="w-5 h-5" />
              </InputLeftElement>
              <Input
                id="organisation"
                type="text"
                placeholder="i.e ACME inc..."
                {...register("organisation")}
              />
            </InputGroup>
          </FormControl>
        )}

        <FormControl isRequired>
          <FormLabel>Your Name</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <UserIcon className="w-5 h-5" />
            </InputLeftElement>
            <Input
              id="name"
              type="text"
              placeholder="i.e John Smith"
              {...register("name")}
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel>Email</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <EnvelopeIcon className="w-4 h-4" />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="i.e John Smith"
              {...register("email")}
            />
          </InputGroup>
          {!!errors.email && (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.password}>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <RiLockPasswordLine />
            </InputLeftElement>
            <Input
              type={isPasswordVisible ? "text" : "password"}
              placeholder="********"
              {...register("password")}
            />
            <InputRightElement width="4.5rem">
              <Button
                h="1.75rem"
                size="sm"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                {isPasswordVisible ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          {!!errors.password && (
            <FormErrorMessage>{errors.password.message}</FormErrorMessage>
          )}
          <div className="flex justify-end mt-0.5">
            <p className="text-xs text-color-gray-600">Min. 8 Characters</p>
          </div>
        </FormControl>

        <Button
          type="submit"
          variant="solid"
          colorScheme="blue"
          isLoading={isLoading}
        >
          Sign Up
        </Button>
      </form>
    </AuthLayout>
  );
}
