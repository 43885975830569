import { gql } from "@apollo/client";

export const GET_KEYWORDS = gql`
  query Keywords {
    keywords {
      id
      key
      value
      updatedAt
      createdAt
    }
  }
`;

export const GET_KEYWORD = gql`
  query Keyword($keywordId: ID!) {
    keyword(id: $keywordId) {
      createdAt
      id
      key
      updatedAt
      value
    }
  }
`;

export const CREATE_KEYWORD = gql`
  mutation CreateKeyword($keyword: KeywordInputType) {
    createKeyword(keyword: $keyword) {
      id
      key
      createdAt
      updatedAt
      value
    }
  }
`;
export const DELETE_KEYWORD = gql`
  mutation DeleteKeyword($deleteKeywordId: ID!) {
    deleteKeyword(id: $deleteKeywordId) {
      createdAt
      id
      key
      updatedAt
      value
    }
  }
`;
export const UPDATE_KEYWORD = gql`
  mutation UpdateKeyword($updateKeywordId: ID!, $keyword: KeywordInputType) {
    updateKeyword(id: $updateKeywordId, keyword: $keyword) {
      createdAt
      id
      key
      updatedAt
      value
    }
  }
`;
