import {
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";

import { SORT_OPTIONS } from "../../../../constants/SORT_OPTIONS";
import { sortOptionVar } from "../../../../store/inbox";
import { RiSortDesc } from "react-icons/ri";

export default function InboxSort() {
  const sortOption = sortOptionVar();
  return (
    <Menu>
      <MenuButton
        variant="ghost"
        as={IconButton}
        icon={<RiSortDesc />}
      ></MenuButton>
      <MenuList>
        <MenuOptionGroup
          title="Sort by:"
          type="radio"
          value={sortOption.value}
          onChange={(value: any) => {
            const foundSortOption = SORT_OPTIONS.find(
              (sortOption) => sortOption.value === value
            );
            sortOptionVar(foundSortOption);
          }}
        >
          {SORT_OPTIONS.map((sortOption) => {
            return (
              <MenuItemOption key={sortOption.label} value={sortOption.value}>
                {sortOption.label}
              </MenuItemOption>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
