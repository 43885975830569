export default function UnauthenticatedLayout(props: any) {
  return (
    <div className="px-2">
      <div className="max-w-sm mx-auto">
        <div className="h-screen flex flex-col py-10">
          <div className="h-full flex place-items-center justify-center">
            <div className="w-full ">
              <div className="flex flex-col gap-2 place-items-center justify-center">
                <h1 className="text-3xl font-bold">{props.title}</h1>
                <div className="flex gap-1 place-items-center text-center">
                  <p className="font-medium text-gray-700">
                    {props.description}
                  </p>
                  {props.actions}
                </div>
              </div>
              <div className="bg-gray-200 h-px w-full my-4"></div>
              {props.children}
            </div>
          </div>
          <div className="text-xs text-gray-500 flex flex-col place-items-center gap-2">
            <div className="flex gap-4">
              <a href="">Terms & Conditions</a>
              <a href="">Privacy Policy</a>
            </div>
            <p>Smartyr Inbox © 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
}
