import { makeVar } from "@apollo/client";

type KeywordsState = {
  [key: string]: {
    isNew: boolean;
    id: string;
    key: string;
    value: string;
  };
};

//On data load, sets all keywords into keywords state
export const keywordsStateVar = makeVar<KeywordsState>({});

export const templateModalOpenVar = makeVar<boolean>(false);
