import { CheckIcon, ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import {
  Button,
  IconButton,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";

import { GoogleCredential } from "../../../types/Organisation";
import { Organisation } from "src/types/Misc";

type Props = {
  selectedLayout: string;
  googleCredential: GoogleCredential | undefined;
  completeStep: (index: number) => void;
};

export default function EmbedCopy({
  selectedLayout,
  googleCredential,
  completeStep,
}: Props) {
  const { onCopy, hasCopied } = useClipboard(
    `<div data-embed="${selectedLayout}" data-organisation="${googleCredential?.publicReviewAPIKey}"></div>`
  );

  if (!googleCredential) {
    return (
      <div className="p-3 border rounded-lg">
        <Text>
          Please authenticate with Google to get your public review API key.
        </Text>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="p-3 border rounded-lg relative">
        <Text>
          {`
    <div
      data-embed="${selectedLayout}"
      data-organisation="${googleCredential?.publicReviewAPIKey}"
    ></div>`}
        </Text>
        <Tooltip placement="left" label={"Copy to clipboard"}>
          <IconButton
            aria-label="Copy to clipboard"
            variant="ghost"
            position="absolute"
            right="1"
            top="1"
            onClick={() => {
              onCopy();
              completeStep(3);
            }}
          >
            {hasCopied ? (
              <CheckIcon className="w-4 h-4" />
            ) : (
              <ClipboardDocumentIcon className="w-5 h-5" />
            )}
          </IconButton>
        </Tooltip>
      </div>
      <Button
        onClick={() => {
          onCopy();
          completeStep(3);
        }}
        variant={"outline"}
      >
        {hasCopied ? "Copied" : "Copy"}
      </Button>
    </div>
  );
}
