import {
  Input,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  InputGroup,
  InputLeftElement,
  useToast,
  FormLabel,
  Select,
  Link,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GET_USER_DETAILS } from "src/apollo/user";
import { ArrowDownIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { CREATE_EMAIL } from "src/apollo/email";
import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Icon,
} from "@chakra-ui/react";
import { GrMail } from "react-icons/gr";
import { RiGoogleLine } from "react-icons/ri";
const providerPresets = [
  {
    id: "gmail",
    name: "Gmail",
    smtp: {
      host: "smtp.gmail.com",
      port: 465,
    },
    imap: {
      host: "imap.gmail.com",
      port: 993,
    },
    instructions: {
      note: "Please read our guide to connect your gmail account",
      link: "https://docs.google.com/document/d/1Q59r_9TQpDCUiVFOBgJ5RLjJmhf-9-vy7aHe-q9LG-U/edit?usp=sharing",
    },
  },
  {
    id: "outlook",
    name: "Outlook",
    smtp: {
      host: "smtp-mail.outlook.com",
      port: 587,
    },
    imap: {
      host: "imap-mail.outlook.com",
      port: 993,
    },
    notes: "",
  },
  {
    id: "yahoo",
    name: "Yahoo",
    smtp: {
      host: "smtp.mail.yahoo.com",
      port: 465,
    },
    imap: {
      host: "imap.mail.yahoo.com",
      port: 993,
    },
    notes: "",
  },
  {
    id: "custom",
    name: "Custom",
    smtp: {
      host: "",
      port: null,
    },
    imap: {
      host: "",
      port: null,
    },
  },
];

const schema = yup.object().shape({
  provider: yup.string().required("Please select a provider"),
  emailOrUsername: yup.string().required("Please enter an email or username"),
  password: yup.string().required("Please enter a password"),
  smtpHost: yup.string().required("Please enter a SMTP host"),
  smtpPort: yup
    .number()
    .required("Please enter a SMTP port")
    .typeError("SMTP port must be a number"),
  imapHost: yup.string().required("Please enter an IMAP host"),
  imapPort: yup
    .number()
    .required("Please enter an IMAP port")
    .typeError("IMAP port must be a number"),
});

type FormData = {
  provider: string;
  emailOrUsername: string;
  password: string;
  smtpHost: string;
  smtpPort: number | null;
  imapHost: string;
  imapPort: number | null;
};

type Props = {
  isOpen: boolean;
  setIsOpen: any;
};

export default function EmailModal({ isOpen, setIsOpen }: Props) {
  const toast = useToast();
  const [advanced, setAdvanced] = useState(false);
  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      provider: "gmail",
      emailOrUsername: "",
      password: "",
      smtpHost: providerPresets[0].smtp.host,
      smtpPort: providerPresets[0].smtp.port,
      imapHost: providerPresets[0].imap.host,
      imapPort: providerPresets[0].imap.port,
    },
  });

  const [createEmail, { loading }] = useMutation(CREATE_EMAIL, {
    refetchQueries: [{ query: GET_USER_DETAILS }, "GetUserDetails"],
    onCompleted: () => {
      setIsOpen(false);
      toast({
        title: "Email connected",
        status: "success",
      });
    },
    onError: (error) => {
      error.graphQLErrors.forEach((err) => {
        switch (err.extensions.code) {
          case "EMAIL_TAKEN":
            setError("emailOrUsername", {
              type: "server",
              message: err.message,
            });
            break;
          default:
            toast({
              title: "Error occurred",
              description: err.message,
              status: "error",
            });
        }
      });
    },
  });

  const onSubmit = (data: FormData) => {
    createEmail({
      variables: {
        name: "",
        email: data.emailOrUsername,
        username: data.emailOrUsername,
        password: data.password,
        smtpHost: data.smtpHost,
        smtpPort: data.smtpPort,
        imapHost: data.imapHost,
        imapPort: data.imapPort,
      },
    });
  };

  const handleChangeProvider = (providerId: string) => {
    const selectedProvider = providerPresets.find(
      (provider) => provider.id === providerId
    );

    if (selectedProvider && selectedProvider.id === "custom") {
      setAdvanced(true);
    } else {
      setAdvanced(false);
    }

    if (selectedProvider) {
      setValue("smtpHost", selectedProvider.smtp.host, {
        shouldValidate: true,
      });
      setValue("smtpPort", selectedProvider.smtp.port, {
        shouldValidate: true,
      });
      setValue("imapHost", selectedProvider.imap.host, {
        shouldValidate: true,
      });
      setValue("imapPort", selectedProvider.imap.port, {
        shouldValidate: true,
      });
    }
  };
  const provider = providerPresets.find(
    (provider) => provider.id === getValues("provider")
  );

  return (
    <Modal size="md" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Connect your email</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            <FormControl isInvalid={!!errors.provider}>
              <FormLabel>Provider</FormLabel>
              <div className="flex gap-2 items-center">
                <div className="grow">
                  <Menu>
                    <MenuButton
                      w="full"
                      textAlign="left"
                      as={Button}
                      variant="outline"
                      fontWeight="normal"
                      rightIcon={<ChevronDownIcon className="h-3 w-3" />}
                    >
                      {providerPresets.find(
                        (provider) => provider.id === getValues("provider")
                      )?.name || "Select Provider"}
                    </MenuButton>
                    <MenuList>
                      {providerPresets.map((provider) => (
                        <MenuItem
                          key={provider.id}
                          onClick={() => {
                            handleChangeProvider(provider.id);
                            setValue("provider", provider.id);
                          }}
                        >
                          {provider.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </div>
              </div>

              {provider?.instructions?.note && (
                <p className="text-sm text-gray-600 mt-1 ">
                  {provider.instructions.note}{" "}
                  {provider.instructions.link && (
                    <a
                      href={provider.instructions.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline"
                    >
                      here
                    </a>
                  )}
                </p>
              )}

              <FormErrorMessage>{errors.provider?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.emailOrUsername} mt={4}>
              <FormLabel>Email/Username</FormLabel>
              <InputGroup>
                <InputLeftElement>
                  <EnvelopeIcon className="w-4 h-4" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="i.e sales@mail.com"
                  {...register("emailOrUsername")}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.emailOrUsername?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password} mt={4}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="********"
                {...register("password")}
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
            <div
              className="cursor-pointer flex place-items-center gap-2 font-medium mt-4 text-sm"
              onClick={() => setAdvanced(!advanced)}
            >
              Advanced Options
              <ChevronDownIcon
                className={`h-3 w-3 ${advanced ? "rotate-180" : ""}`}
              />
            </div>
            {advanced && (
              <>
                <FormControl isInvalid={!!errors.smtpHost} mt={4}>
                  <FormLabel>SMTP Host</FormLabel>
                  <Input
                    type="text"
                    placeholder="i.e smtp.gmail.com"
                    {...register("smtpHost")}
                  />
                  <FormErrorMessage>
                    {errors.smtpHost?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.smtpPort} mt={4}>
                  <FormLabel>SMTP Port</FormLabel>
                  <Input
                    type="number"
                    placeholder="i.e 465"
                    {...register("smtpPort")}
                  />
                  <FormErrorMessage>
                    {errors.smtpPort?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.imapHost} mt={4}>
                  <FormLabel>IMAP Host</FormLabel>
                  <Input
                    type="text"
                    placeholder="i.e imap.gmail.com"
                    {...register("imapHost")}
                  />
                  <FormErrorMessage>
                    {errors.imapHost?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.imapPort} mt={4}>
                  <FormLabel>IMAP Port</FormLabel>
                  <Input
                    type="number"
                    placeholder="i.e 993"
                    {...register("imapPort")}
                  />
                  <FormErrorMessage>
                    {errors.imapPort?.message}
                  </FormErrorMessage>
                </FormControl>
              </>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" mr={3} onClick={() => setIsOpen(false)}>
            Close
          </Button>
          <Button
            isLoading={loading}
            colorScheme="blue"
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
