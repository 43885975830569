import { Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

import { ChangeEvent } from "react";
import CreateContact from "./CreateContact";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import BackButton from "src/components/BackButton";

type Props = {
  search: string;
  setSearch: any;
  sortOption: any;
  setSortOption: any;
  filterOption: any;
  setFilterOption: any;
};

export default function ContactsAppBar({
  search,
  setSearch,
  sortOption,
  setSortOption,
  filterOption,
  setFilterOption,
}: Props) {
  const location = useLocation();
  return (
    <div>
      <div className="p-2 border-b flex justify-between place-items-center">
        <div className="flex gap-1 place-items-center">
          <BackButton />
          <h2 className="text-lg font-semibold">
            {location.pathname !== "/inbox/new-message"
              ? "Contacts"
              : "Send message"}
          </h2>
        </div>
        <CreateContact />
      </div>

      <div className="px-2 pt-2">
        <div className="flex gap-2 justify-between">
          <InputGroup className="max-w-lg">
            <InputLeftElement pointerEvents="none">
              <MagnifyingGlassIcon className="w-5 h-5" />
            </InputLeftElement>
            <Input
              placeholder="Search contacts..."
              value={search}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
            />
          </InputGroup>
        </div>
      </div>
    </div>
  );
}
