import { Avatar, Box, Heading, VStack } from "@chakra-ui/react";

import { Contact } from "../../../../types/Contact";
import ContactForm from "../../../contacts/contact_view/ContactForm";

type Props = {
  contact: Contact;
};

export default function SideBar({ contact }: Props) {
  return (
    <div className="w-full max-w-lg border-l py-3 px-3">
      <div className="flex flex-col gap-2 mb-2 place-items-center mb-4 p-4">
        <Avatar size="lg" name={contact.name} src={contact.googleAvatar} />
        <Heading size="md">{contact.name}</Heading>
      </div>
      <ContactForm contact={contact} />
    </div>
  );
}
