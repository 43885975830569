import { gql } from "@apollo/client";

export const GET_CONTACT_INBOX_EVENTS = gql`
  query GetContactInboxEvents($contactId: ID!, $after: String) {
    contact(id: $contactId) {
      id
      createdAt
      updatedAt
      lastActivityAt
      facebookId
      name
      phone
      email
      googleAvatar
      open
    }
    inboxEvents(
      first: 20
      after: $after
      filter: { contact_id: $contactId }
      sort: { order: desc, field: "createdAt" }
    ) {
      pageInfo {
        nodes
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          direction
          channel
          createdAt
          properties
          contact {
            id
            name
            email
            googleAvatar
            facebookId
            phone
          }
          sender {
            id
            name
            email
          }
        }
      }
    }
  }
`;
export const SEND_EMAIL = gql`
  mutation SendEmail($email: SendEmailType) {
    sendEmail(email: $email) {
      id
      properties
      channel
    }
  }
`;

export const SEND_TEXT_MESSAGE = gql`
  mutation SendSMS($message: SendTextMessageType) {
    sendTextMessage(message: $message) {
      id
      createdAt
      updatedAt
      direction
      channel
      properties
    }
  }
`;

export const SEND_FACEBOOK_CHAT = gql`
  mutation SendFacebookChat($message: SendFacebookChatType) {
    sendFacebookChat(message: $message) {
      id
      properties
      channel
      direction
      createdAt
      updatedAt
    }
  }
`;

export const SEND_FACEBOOK_RECOMMENDATION = gql`
  mutation SendFacebookRecommendation(
    $message: SendFacebookRecommendationType
  ) {
    sendFacebookRecommendation(message: $message) {
      id
      properties
      channel
      direction
      createdAt
      updatedAt
    }
  }
`;

export const SEND_REVIEW_REPLY = gql`
  mutation SendReviewReply(
    $commentOnGoogleReviewId: ID!
    $comment: GoogleReviewCommentType
  ) {
    commentOnGoogleReview(id: $commentOnGoogleReviewId, comment: $comment) {
      id
      properties
      channel
      direction
      createdAt
      updatedAt
    }
  }
`;
