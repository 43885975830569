import { Avatar } from "@chakra-ui/react";
import { Contact } from "../../../../../types/Contact";
import { InboxEvent } from "../../../../../types/InboxEvent";
import { dateFormatter } from "../../../../../utilities/dateFormatter";
import { useState } from "react";
import { GrAttachment } from "react-icons/gr";
import DOMPurify from "dompurify";

type Props = {
  contact: Contact;
  inboxEvent: InboxEvent;
};

export default function Email({ contact, inboxEvent }: Props) {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const { createdAt, id, direction, properties, sender } = inboxEvent;

  function showMessageDetails() {
    setIsDetailsVisible(!isDetailsVisible);
  }

  // Convert Markdown to HTML and sanitize
  const sanitizedHtml =
    "html" in properties
      ? DOMPurify.sanitize(properties.html || "")
      : "text" in properties
      ? DOMPurify.sanitize(properties.text || "")
      : "";
  const emailContent = (
    <div
      className={`border rounded-lg ${
        inboxEvent.direction === "SENT" ? "bg-blue-50" : "bg-white"
      } w-fit overflow-x-auto p-1`}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      onClick={(e) => e.stopPropagation()}
    />
  );

  if (direction === "SENT") {
    return (
      <div
        className="flex gap-2 max-w-[95%] md:max-w-[80%] mb-2 place-items-start ml-auto justify-end"
        onClick={showMessageDetails}
      >
        <div className="flex flex-col place-items-end break-words w-fit">
          <div
            className={`p-3 ml-auto w-full bg-blue-100 rounded-xl ${
              "attachments" in properties && properties.attachments.length > 0
                ? "rounded-b-none"
                : ""
            }`}
            onClick={(e) => e.stopPropagation()}
          >
            <h3 className="mb-1 font-medium">
              {"subject" in properties && properties.subject}
            </h3>
            {emailContent}
          </div>
          {"attachments" in properties && properties.attachments.length > 0 && (
            <a
              href={`${process.env.REACT_APP_API_URL}/api/v1/s3?file=${properties.attachments[0]?.key}`}
              target="_blank"
              rel="noreferrer"
              aria-label="See attachment"
              className="p-3 ml-auto rounded-xl rounded-t-none bg-orange-50 hover:bg-orange-100 w-full"
            >
              <div className="flex items-center gap-2 font-semibold">
                <GrAttachment className="h-5 w-5" />
                <p>
                  Download Attachment
                  {properties.attachments.length > 1 ? "s" : ""}
                </p>
              </div>
            </a>
          )}
          {"fromEmail" in properties && isDetailsVisible && (
            <p className="mt-px text-sm text-gray-600">
              {properties.fromEmail} - {dateFormatter(createdAt)}
            </p>
          )}
        </div>
        <Avatar
          size="sm"
          name={sender && sender.name ? sender.name : ""}
          src={""}
        />
      </div>
    );
  } else if (direction === "RECEIVED") {
    return (
      <div
        className="max-w-[95%] md:max-w-[80%] justify-start flex gap-2 mb-2 place-items-start"
        onClick={showMessageDetails}
      >
        <Avatar
          size="sm"
          name={contact.name ? contact.name : ""}
          src={contact.googleAvatar}
        />
        <div
          className="flex flex-col place-items-start break-words overflow-auto w-fit"
          // onClick={(e) => e.stopPropagation()}
        >
          <div
            className={`p-3 bg-gray-100 rounded-xl w-full ${
              "attachments" in properties && properties.attachments.length > 0
                ? "rounded-b-none"
                : ""
            }`}
          >
            <div className="flex justify-between gap-2 mb-2">
              <h3 className=" font-medium">
                {"subject" in properties && properties.subject}
              </h3>
              <p className="text-sm text-gray-600">
                {dateFormatter(createdAt)}
              </p>
            </div>
            {emailContent}
          </div>
          {"attachments" in properties && properties.attachments.length > 0 && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                properties.attachments.forEach((attachment) => {
                  const { key } = attachment;
                  const a = document.createElement("a");
                  a.href = `${process.env.REACT_APP_API_URL}/api/v1/s3?file=${key}`;
                  a.target = "_blank";
                  a.rel = "noopener noreferrer";
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                });
              }}
              aria-label="See attachment"
              className="p-3 ml-auto rounded-xl rounded-t-none bg-orange-50 hover:bg-orange-100 w-full"
            >
              <div className="flex items-center gap-2 font-semibold">
                <GrAttachment className="h-5 w-5" />
                <p>
                  Download Attachment
                  {properties.attachments.length > 1 ? "s" : ""}
                </p>
              </div>
            </div>
          )}
          {"fromEmail" in properties && isDetailsVisible && (
            <p className="mt-px text-sm text-gray-600">
              {properties.fromEmail} - {dateFormatter(createdAt)}
            </p>
          )}
        </div>
      </div>
    );
  }
  return null;
}
