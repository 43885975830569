import {
  Avatar,
  Checkbox,
  Flex,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  GET_INBOX_CONTACTS,
  SET_CONTACT_VIEWED,
} from "../../../../apollo/inbox";
import { InboxEvent, InboxEventProperties } from "../../../../types/InboxEvent";

import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import { CHANNELS } from "../../../../constants/CHANNELS";
import { Contact } from "../../../../types/Contact";
import { NavLink } from "react-router-dom";
import { dateFormatter } from "../../../../utilities/dateFormatter";
import getContactName from "../../../../utilities/getContactName";
import mergeClassNames from "../../../../utilities/mergeClassNames";
import { useMutation, useReactiveVar } from "@apollo/client";
import { multiSelectVar } from "src/store/inbox";

type Props = {
  contact: Contact;
};

export default function InboxListItem({ contact }: Props) {
  const toast = useToast();

  const latestMessage: InboxEvent = contact.inboxEvents!.edges[0].node;

  const multiSelect = useReactiveVar(multiSelectVar);

  const messageMap = {
    SMS: "attachments" in latestMessage.properties ? "attachment" : "message",
    REVIEW:
      "reply" in latestMessage.properties && latestMessage.properties.reply
        ? "reply"
        : "comment",
    EMAIL: "subject",
    FACEBOOK_CHAT:
      "attachments" in latestMessage.properties ? "attachment" : "chat",
    FACEBOOK_RECOMMENDATION:
      "recommendationMessage" in latestMessage.properties
        ? "recommendationMessage"
        : "replyMessage",
    FEEDBACK: "message",
  };

  const dateMap = {
    REVIEW:
      "reply" in latestMessage.properties
        ? latestMessage.properties.replyUpdatedAt!
        : latestMessage.createdAt!,
    SMS: latestMessage.createdAt!,
    EMAIL: latestMessage.createdAt!,
    FACEBOOK_CHAT: latestMessage.createdAt!,
    FEEDBACK: latestMessage.createdAt!,
    FACEBOOK_RECOMMENDATION: latestMessage.createdAt!,
  };

  const [setContactViewed] = useMutation(SET_CONTACT_VIEWED, {
    onError: (error) => {
      console.log(error);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
    //Refetch queries cause scroll to bounce
  });

  const handleChecked = (contactId: string) => {
    if (multiSelect.selectedContacts.includes(contactId)) {
      multiSelectVar({
        ...multiSelect,
        selectedContacts: multiSelect.selectedContacts.filter(
          (checkedContact) => {
            return checkedContact !== contactId;
          }
        ),
      });
    } else {
      multiSelectVar({
        ...multiSelect,
        selectedContacts: [...multiSelect.selectedContacts, contactId],
      });
    }
  };

  function getMessage(latestMessage: InboxEvent) {
    const messageContent: string =
      latestMessage.properties[
        messageMap[
          latestMessage.channel as keyof typeof messageMap
        ] as keyof InboxEventProperties
      ];

    if (
      "attachments" in latestMessage.properties &&
      latestMessage.properties?.attachments?.length > 0
    ) {
      return "Sent an attachment";
    } else {
      return messageContent;
    }
  }

  function handleClick(inboxEventId: string) {
    setContactViewed({
      variables: {
        markInboxEventViewedId: inboxEventId,
      },
    });
  }

  return (
    <div className="flex gap-2 place-items-center">
      {multiSelect.isMultiSelectActive && (
        <Checkbox
          ml={2}
          onChange={() => handleChecked(contact.id)}
          checked={multiSelect.selectedContacts.includes(contact.id)}
        ></Checkbox>
      )}
      <NavLink
        to={`${contact.id}`}
        className={({ isActive }) =>
          mergeClassNames(
            isActive
              ? "bg-gray-100 shadow-sm border-gray-200"
              : "hover:bg-gray-100 hover:border-gray-200 border-white",
            "pl-2 pr-4 py-2 mt-0.5 flex rounded-lg gap-3 place-items-center cursor-pointer transition-colors ease-in-out duration-300 w-full border"
          )
        }
        onClick={() => handleClick(latestMessage.id!)}
      >
        <Avatar
          height={10}
          width={10}
          name={contact.name}
          src={contact.googleAvatar}
        />
        <div className="w-full">
          <div className="justify-between flex place-items-center gap-2">
            <h3 className="font-medium">{getContactName(contact)}</h3>
            <div className="flex place-items-center gap-2">
              <span className="text-xs">
                {dateFormatter(
                  dateMap[latestMessage.channel as keyof typeof dateMap]
                )}
              </span>
              {CHANNELS[latestMessage.channel].iconSmall}
            </div>
          </div>

          <div className="justify-between flex place-items-center gap-2">
            <div className="flex gap-1.5 place-items-center">
              {(latestMessage.direction === "SENT" ||
                (latestMessage.channel === "REVIEW" &&
                  "reply" in latestMessage.properties)) && (
                <ArrowUturnLeftIcon className="flex-shrink-0 w-3 h-3" />
              )}

              <p className="line-clamp-1 text-sm">
                {getMessage(latestMessage)}
              </p>
            </div>

            {!latestMessage.viewed && (
              <div className="flex-shrink-0 w-2 h-2 bg-blue-500 rounded-full"></div>
            )}
          </div>
        </div>
      </NavLink>
    </div>
  );
}
