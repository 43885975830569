import { useEffect } from "react";
import { useLocation, useNavigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import DesktopNavBar from "../navigation/DesktopNavBar";
import MobileNavBar from "../navigation/MobileNavBar";
import { SCREENS } from "../constants/SCREENS";
import { useMediaQuery } from "@chakra-ui/react";
import { GET_USER_DETAILS } from "src/apollo/user";
import { useQuery } from "@apollo/client";

export default function AuthenticatedLayout() {
  const outlet = useOutlet();
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();
  const [isDesktop] = useMediaQuery(SCREENS.DESKTOP);
  const isNestedRoute = location.pathname.split("/").length > 2;

  const { loading, error } = useQuery(GET_USER_DETAILS, {
    skip: !auth.token, // Skip this query if no token is available
    onError: (error) => {
      console.error("[AUTH] GraphQL error fetching user details:", error);
      auth.logout();
      navigate("/login");
    },
  });

  useEffect(() => {
    if (!auth.token || !auth.refreshToken) {
      console.log("[AUTH] No valid tokens, redirecting to login.");
      navigate("/login");
    } else {
      const checkToken = async () => {
        if (await auth.isTokenExpired()) {
          console.log("[AUTH] Token expired, refreshing...");
          auth.refreshCurrentToken().catch((error) => {
            console.error("[AUTH] Error while refreshing token:", error);
            auth.logout();
            navigate("/login");
          });
        }
      };

      checkToken();

      const interval = setInterval(checkToken, 1000 * 60 * 5); // Every 5 minutes
      return () => clearInterval(interval);
    }
  }, [auth, navigate]);

  if (loading) {
    return (
      <div className="absolute w-screen h-screen flex justify-center items-center">
        <div className="w-16 h-16">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <circle
              fill="#308FFF"
              stroke="#308FFF"
              strokeWidth="15"
              r="15"
              cx="40"
              cy="65"
            >
              <animate
                attributeName="cy"
                calcMode="spline"
                dur="2"
                values="65;135;65;"
                keySplines=".5 0 .5 1;.5 0 .5 1"
                repeatCount="indefinite"
                begin="-.4"
              />
            </circle>
            <circle
              fill="#308FFF"
              stroke="#308FFF"
              strokeWidth="15"
              r="15"
              cx="100"
              cy="65"
            >
              <animate
                attributeName="cy"
                calcMode="spline"
                dur="2"
                values="65;135;65;"
                keySplines=".5 0 .5 1;.5 0 .5 1"
                repeatCount="indefinite"
                begin="-.2"
              />
            </circle>
            <circle
              fill="#308FFF"
              stroke="#308FFF"
              strokeWidth="15"
              r="15"
              cx="160"
              cy="65"
            >
              <animate
                attributeName="cy"
                calcMode="spline"
                dur="2"
                values="65;135;65;"
                keySplines=".5 0 .5 1;.5 0 .5 1"
                repeatCount="indefinite"
                begin="0"
              />
            </circle>
          </svg>
        </div>
      </div>
    );
  }

  if (error) {
    return null; // The error handling and redirection is managed in the onError callback of useQuery.
  }

  return (
    <div className="flex flex-col w-screen h-[100vh] md:flex-row">
      {isDesktop && <DesktopNavBar />}
      <div className="w-full h-full">{outlet}</div>
      {!isDesktop && !isNestedRoute && <MobileNavBar />}
    </div>
  );
}
