import React from "react";
import { NavLink } from "react-router-dom";
import mergeClassNames from "../../../utilities/mergeClassNames";
export default function SettingsNavLink({ path, icon, name }: any) {
  return (
    <NavLink
      key={path}
      to={path}
      className={({ isActive }) =>
        mergeClassNames(
          isActive
            ? "border-gray-200 bg-gray-100 text-black"
            : "hover:bg-gray-100 hover:border-gray-200 border-white text-gray-600 ",
          " border gap-2 inline-flex items-center whitespace-nowrap rounded-md md:text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 px-2 py-2 bg-muted hover:bg-muted justify-start"
        )
      }
    >
      {icon}
      {name}
    </NavLink>
  );
}
