import { IconButton } from "@chakra-ui/react";
import React from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { mediaUrlsVar } from "../../../../../store/messaging";
import { useReactiveVar } from "@apollo/client";

export default function SMSAttachment() {
  const mediaUrls = useReactiveVar(mediaUrlsVar);

  function removeImage(selectedMediaUrl: string) {
    const newMediaUrls = mediaUrls.filter((mediaUrl) => {
      return mediaUrl !== selectedMediaUrl;
    });

    mediaUrlsVar(newMediaUrls);
  }
  return (
    <div className="flex gap-2">
      {mediaUrls.map((mediaUrl) => {
        return (
          <div className="relative border rounded-md h-64 overflow-hidden">
            <img
              key={mediaUrl}
              src={mediaUrl}
              alt=""
              className="object-center cover h-full"
            />
            <IconButton
              position="absolute"
              size="sm"
              className="right-2 top-2"
              aria-label={""}
              onClick={() => removeImage(mediaUrl)}
            >
              <XMarkIcon className="h-4 w-4" />
            </IconButton>
          </div>
        );
      })}
    </div>
  );
}
