import {
  Avatar,
  AvatarGroup,
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BackButton from "../../../components/BackButton";
import { Contact } from "../../../types/Contact";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import InputBar from "./input/InputBar";
import getContactChannelIcons from "../../../utilities/getContactChannelIcons";
import { inputStatesVar } from "../../../store/messaging";
import { useReactiveVar } from "@apollo/client";

const channelsMap = {
  SMS: "phone",
  EMAIL: "email",
  FACEBOOK_CHAT: "facebook",
  FEEDBACK: "feedback",
};

export default function GroupMessageView() {
  const inputStates = useReactiveVar(inputStatesVar);
  const navigate = useNavigate();
  const location = useLocation();
  const [channels, setChannels] = useState<string[]>([]);
  const selectedContacts: Contact[] = location.state as Contact[];
  const [contacts, setContacts] = useState<Contact[]>(selectedContacts);

  useEffect(() => {
    //get all active channels for group
    const activeChannels = getChannels(selectedContacts);
    setChannels(activeChannels);

    inputStatesVar({
      emailSubject: "",
      emailBody: "",
      SMSBody: "",
      reviewReply: "",
      facebookChat: "",
      facebookRecommendation: "",
      feedback: "",
      selectedChannel: activeChannels[0],
    });
  }, []);

  useEffect(() => {
    //Set active contacts whenever selected channel is changed
    //gets channel name i.e SMS = "phone"
    if (inputStates.selectedChannel) {
      const channel =
        channelsMap[inputStates.selectedChannel as keyof typeof channelsMap];
      //filters contacts that has only the channel
      const filteredContacts = contacts.filter((contact: Contact) => {
        return Boolean(contact[channel as keyof typeof contact]);
      });
      setContacts(filteredContacts);
    }
  }, [inputStates.selectedChannel]);

  function getChannels(contacts: Contact[]) {
    const channels = [];

    if (contacts.some((contact: Contact) => contact.phone)) {
      channels.push("SMS");
    }
    if (contacts.some((contact: Contact) => contact.email)) {
      channels.push("EMAIL");
    }
    if (contacts.some((contact: Contact) => contact.facebookId)) {
      channels.push("FACEBOOK_CHAT");
    }

    // if (contacts.some((contact: Contact) => contact.email)) {
    //   channels.push("FEEDBACK");
    // }
    return channels;
  }

  if (contacts)
    return (
      <Flex className="relative flex-col w-full h-full">
        <Flex p={3} className="justify-between w-full border-b">
          <Flex className="gap-3 place-items-center">
            <BackButton />
            <Flex className="gap-1">
              <AvatarGroup size="sm" max={4}>
                {contacts.map((contact: Contact) => {
                  return (
                    <Avatar
                      key={contact.id}
                      name={contact.name}
                      src={contact.googleAvatar}
                    />
                  );
                })}
              </AvatarGroup>
              <Menu>
                <MenuButton as={IconButton} variant="ghost">
                  <EllipsisVerticalIcon className="w-5 h-5 mx-auto" />
                </MenuButton>
                <MenuList>
                  {selectedContacts.map((contact: Contact) => {
                    return (
                      <MenuItem
                        key={contact.id}
                        isDisabled={!contacts.includes(contact)}
                        gap={2}
                        onClick={() => navigate(`/contacts/${contact.id}`)}
                      >
                        {contact.name}
                        {getContactChannelIcons(contact)}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
        </Flex>
        <Center className="w-full h-full ">
          <Text className="text-gray-500 max-w-[80%] text-center">
            Group messages show under each unique contact.
          </Text>
        </Center>
        <InputBar channels={channels} contacts={contacts} />
      </Flex>
    );
  return null;
}
