import {
  GET_CONTACT_INBOX_EVENTS,
  SEND_EMAIL,
} from "../../../../apollo/messaging";
import {
  attachmentStateVar,
  contactStatesVar,
  filesVar,
  inputStatesVar,
  isMessageSendingVar,
} from "../../../../store/messaging";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";

import { Contact } from "../../../../types/Contact";
import { GET_INBOX_CONTACTS } from "../../../../apollo/inbox";
import { GET_USER_DETAILS } from "../../../../apollo/user";
import { useToast } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

export default function useEmail(
  contact: Contact | undefined,
  contacts: Contact[] | undefined
) {
  const contactStates = useReactiveVar(contactStatesVar);
  const inputStates = useReactiveVar(inputStatesVar);
  const attachmentState = useReactiveVar(attachmentStateVar);
  const emailSubjectRef = useRef(inputStates.emailSubject);
  const emailBodyRef = useRef(inputStates.emailBody);
  const files = useReactiveVar(filesVar);

  const { data: userData } = useQuery(GET_USER_DETAILS, {
    onError(error) {
      console.log(error);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
  });
  const toast = useToast();
  const [
    sendEmail,
    { data: emailData, loading: emailLoading, error: emailError },
  ] = useMutation(SEND_EMAIL, {
    refetchQueries: [
      {
        query: GET_CONTACT_INBOX_EVENTS,
        variables: { contactId: contact!.id },
      },
      "GetContactInboxEvents",
      {
        query: GET_INBOX_CONTACTS,
      },
      "GetInboxContacts",
    ],

    onCompleted() {
      attachmentStateVar(null);
      inputStatesVar({
        ...inputStates,
        emailSubject: "",
        emailBody: "",
      });
      if (contact) {
        contactStatesVar({
          ...contactStates,
          [contact.id]: {
            ...contactStates[contact.id],
            emailSubject: "",
            emailBody: "",
          },
        });
      }
      isMessageSendingVar(false);
    },
    onError(error) {
      console.log(error);
      toast({
        title: "Error sending email",
        description: error.message,
        status: "error",
      });
    },
  });

  useEffect(() => {
    emailSubjectRef.current = inputStates.emailSubject;
    emailBodyRef.current = inputStates.emailBody;
  }, [inputStates.emailBody, inputStates.emailSubject]);

  function getToEmails() {
    if (contact) {
      return [contact.email];
    } else if (contacts)
      return contacts.map((contact: Contact) => {
        return contact.email;
      });
  }

  function getContactIds() {
    if (contact) {
      return [contact.id];
    } else if (contacts)
      return contacts.map((contact: Contact) => {
        return contact.id;
      });
  }

  function handleSubmitEmail() {
    if (!emailBodyRef.current && !attachmentState) return;
    isMessageSendingVar(true);
    if (userData && !userData.me.organisation.emails.length) {
      toast({
        title: "Error occurred.",
        description: "Please add an email to your organisation",
        status: "error",
      });
      return;
    }

    const getVariables = () => {
      if (attachmentState) {
        return {
          email: {
            contactId: getContactIds(),
            fromEmail: "inbox@smartyr.biz",
            subject: emailSubjectRef.current,
            text: emailBodyRef.current,
            toEmail: getToEmails(),
            attachments: [attachmentState],
          },
        };
      } else {
        return {
          email: {
            contactId: getContactIds(),
            fromEmail: "inbox@smartyr.biz",
            subject: emailSubjectRef.current,
            text: emailBodyRef.current,
            toEmail: getToEmails(),
          },
        };
      }
    };

    sendEmail({
      variables: getVariables(),
    });
  }

  return { handleSubmitEmail, emailLoading, emailError, emailData };
}
