import { Center, Flex, useToast } from "@chakra-ui/react";
import {
  InboxEventCollection,
  InboxEventEdge,
} from "../../../types/InboxEvent";
import {
  contactStatesVar,
  inputStatesVar,
  isMessageSendingVar,
} from "../../../store/messaging";
import { useEffect, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { CircularProgress } from "@chakra-ui/progress";
import { Contact } from "../../../types/Contact";
import { GET_CONTACT_INBOX_EVENTS } from "../../../apollo/messaging";
import InputBar from "./input/InputBar";
import MessageAppBar from "./MessageAppBar";
import MessageList from "./message_list/MessageList";
import { SCREENS } from "../../../constants/SCREENS";
import SideBar from "./side_bar/SideBar";
import { useMediaQuery } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

type QueryData = {
  contact: Contact;
  inboxEvents: InboxEventCollection;
};

export default function SingleMessageView() {
  const [isDesktop] = useMediaQuery(SCREENS.DESKTOP);
  const [channels, setChannels] = useState<string[]>([]);
  const [isSideBarVisible, setIsSideBarVisible] = useState(isDesktop);
  const { inboxId } = useParams();
  const contactStates = useReactiveVar(contactStatesVar);
  const isMessageSending = useReactiveVar(isMessageSendingVar);
  const toast = useToast();

  const { loading, data, fetchMore, refetch } = useQuery<QueryData>(
    GET_CONTACT_INBOX_EVENTS,
    {
      variables: { contactId: inboxId },
      pollInterval: 10000,
      onError: (error) => {
        toast({
          title: "Error occurred",
          description: error.message,
          status: "error",
        });
      },
      onCompleted(data) {},
    }
  );

  useEffect(() => {
    if (data) {
      const channelsArray = getChannels(data.contact);
      setChannels(channelsArray);

      const selectedChannel = getSelectedChannel(
        data.inboxEvents.edges,
        channelsArray
      );
      const contactState = {
        emailSubject: "",
        emailBody: "",
        SMSBody: "",
        reviewReply: "",
        facebookChat: "",
        facebookRecommendation: "",
        feedback: "",
        selectedChannel: selectedChannel,
      };

      const selectedChannelIsValid = channels.includes(
        contactStates[data.contact.id]?.selectedChannel!
      );

      inputStatesVar({
        emailSubject: contactStates[data.contact.id]?.emailSubject || "",
        emailBody: contactStates[data.contact.id]?.emailBody || "",
        SMSBody: contactStates[data.contact.id]?.SMSBody || "",
        reviewReply: contactStates[data.contact.id]?.reviewReply || "",
        facebookChat: contactStates[data.contact.id]?.facebookChat || "",
        facebookRecommendation:
          contactStates[data.contact.id]?.facebookRecommendation || "",
        feedback: contactStates[data.contact.id]?.feedback || "",
        selectedChannel: selectedChannelIsValid
          ? contactStates[data.contact.id]?.selectedChannel
          : selectedChannel,
      });

      contactStatesVar({
        ...contactStates,
        [data.contact.id]: {
          ...contactState,
        },
      });
    }
  }, [data]);

  const isFBRecommendation = (contact: Contact) => {
    const inboxEvents = data?.inboxEvents?.edges.filter(
      (edge) => edge.node.channel === "FACEBOOK_RECOMMENDATION"
    );

    return inboxEvents?.length ? true : false;
  };

  function getChannels(contact: Contact) {
    const channels: string[] = [];
    if (contact.phone) channels.push("SMS");
    if (contact.googleAvatar) channels.push("REVIEW");
    if (contact.email) channels.push("EMAIL");
    if (contact.facebookId) channels.push("FACEBOOK_CHAT");
    if (isFBRecommendation(contact)) channels.push("FACEBOOK_RECOMMENDATION");
    return channels;
  }

  function getSelectedChannel(
    inboxEvents: InboxEventEdge[],
    channels: string[]
  ) {
    const firstChannel = inboxEvents[0]?.node.channel;
    return channels.includes(firstChannel) ? firstChannel : channels[0];
  }

  if (loading)
    return (
      <Center w="100%" h="100%">
        <CircularProgress isIndeterminate color="blue.400" />
      </Center>
    );

  if (data && contactStates[data.contact.id])
    return (
      <div className="w-full flex ">
        <div className="w-full h-screen flex flex-col overflow-hidden">
          <MessageAppBar
            isSideBarVisible={isSideBarVisible}
            setIsSideBarVisible={setIsSideBarVisible}
            contact={data.contact}
          />
          <div className="w-full flex-1 flex">
            <div className="h-full w-full flex flex-col">
              <MessageList fetchMore={fetchMore} data={data} />
              <InputBar channels={channels} contact={data.contact} />
            </div>
          </div>
        </div>
        {isSideBarVisible && isDesktop && <SideBar contact={data.contact} />}
      </div>
    );

  return null;
}
