import {
  format,
  isValid,
  isWithinInterval,
  parseISO,
  subDays,
  subHours,
  subWeeks,
} from "date-fns";

import { subYears } from "date-fns/esm";

export const dateFormatter = (utcDate: string, size?: string) => {
  const date = parseISO(utcDate);
  if (!isValid(date)) return "invalid date";

  const dateIsWithinYear = isWithinInterval(date, {
    start: subYears(new Date(), 1),
    end: new Date(),
  });
  const dateIsWithinWeek = isWithinInterval(date, {
    start: subWeeks(new Date(), 1),
    end: new Date(),
  });
  const dateIsWithinDay = isWithinInterval(date, {
    start: subDays(new Date(), 1),
    end: new Date(),
  });
  const dateIsWithinHour = isWithinInterval(date, {
    start: subHours(new Date(), 1),
    end: new Date(),
  });

  if (dateIsWithinDay) {
    return format(date, "h:mmaaa");
  }
  if (dateIsWithinWeek) {
    return format(date, "E, h:mmaaa");
  }
  if (dateIsWithinYear) {
    return format(date, "MMM d, h:mmaa");
  } else {
    return format(date, "d/M/yy, h:mmaaa");
  }
};
