import { gql } from "@apollo/client";

export const GET_CONTACTS = gql`
  query GetContacts(
    $filter: ContactFilterInput
    $sort: ContactSortInput
    $after: String
    $first: Int
  ) {
    contacts(after: $after, first: $first, filter: $filter, sort: $sort) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          id
          createdAt
          updatedAt
          lastActivityAt
          name
          phone
          email
          googleAvatar
          open
        }
      }
    }
  }
`;
