import { Avatar } from "@chakra-ui/react";
import React from "react";
import { ROLES } from "src/constants/ROLES";

export default function DesktopNavUser({ name, role }: any) {
  return (
    <div className="flex  gap-3 p-2 place-items-center ">
      <Avatar size="md" />
      <div>
        <h3 className="font-medium">{name}</h3>
        <span className="text-gray-600 text-xs">
          {ROLES[role as keyof typeof ROLES]}
        </span>
      </div>
    </div>
  );
}
