import React from "react";

export default function DesktopNavCompany({ name }: any) {
  return (
    <div className="flex px-2 gap-2.5 place-items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        className="flex-shrink-0 w-9 h-9"
      >
        <circle cx="16" cy="16" r="16" fill="#308FFF" />
        <path
          d="M13.663 21H18.336M16 7V8M22.364 9.636L21.657 10.343M25 16H24M8 16H7M10.343 10.343L9.636 9.636M12.464 19.536C11.7649 18.8367 11.2888 17.9458 11.096 16.9759C10.9032 16.006 11.0023 15.0008 11.3807 14.0872C11.7592 13.1737 12.4001 12.3928 13.2223 11.8435C14.0445 11.2941 15.0111 11.0009 16 11.0009C16.9889 11.0009 17.9555 11.2941 18.7777 11.8435C19.5999 12.3928 20.2408 13.1737 20.6193 14.0872C20.9977 15.0008 21.0968 16.006 20.904 16.9759C20.7112 17.9458 20.2351 18.8367 19.536 19.536L18.988 20.083C18.6747 20.3963 18.4262 20.7683 18.2567 21.1777C18.0872 21.5871 17.9999 22.0259 18 22.469V23C18 23.5304 17.7893 24.0391 17.4142 24.4142C17.0391 24.7893 16.5304 25 16 25C15.4696 25 14.9609 24.7893 14.5858 24.4142C14.2107 24.0391 14 23.5304 14 23V22.469C14 21.574 13.644 20.715 13.012 20.083L12.464 19.536Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div>
        <p className=" font-medium">{name}</p>
        <p className="text-xs text-gray-500">Smartyr Inbox</p>
      </div>
    </div>
  );
}
