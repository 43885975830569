/* eslint-disable no-useless-escape */
import "./App.css";
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
  ApolloProvider,
} from "@apollo/client";
import RootNavigator from "./routes/RootNavigator";
import { useAuth } from "./hooks/useAuth";
function App() {
  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  });
  const { token } = useAuth();

  const ascii = [
    `╔═══════════════════════════════════════════════════════════════╗`,
    `║ ███████╗███╗   ███╗ █████╗ ██████╗ ████████╗██╗   ██╗██████╗  ║`,
    `║ ██╔════╝████╗ ████║██╔══██╗██╔══██╗╚══██╔══╝╚██╗ ██╔╝██╔══██╗ ║`,
    `║ ███████╗██╔████╔██║███████║██████╔╝   ██║    ╚████╔╝ ██████╔╝ ║`,
    `║ ╚════██║██║╚██╔╝██║██╔══██║██╔══██╗   ██║     ╚██╔╝  ██╔══██╗ ║`,
    `║ ███████║██║ ╚═╝ ██║██║  ██║██║  ██║   ██║      ██║   ██║  ██║ ║`,
    `║ ╚══════╝╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝      ╚═╝   ╚═╝  ╚═╝ ║`,
    `║                                                               ║`,
    `║             ██╗███╗   ██╗██████╗  ██████╗ ██╗  ██╗            ║`,
    `║             ██║████╗  ██║██╔══██╗██╔═══██╗╚██╗██╔╝            ║`,
    `║             ██║██╔██╗ ██║██████╔╝██║   ██║ ╚███╔╝             ║`,
    `║             ██║██║╚██╗██║██╔══██╗██║   ██║ ██╔██╗             ║`,
    `║             ██║██║ ╚████║██████╔╝╚██████╔╝██╔╝ ██╗            ║`,
    `║             ╚═╝╚═╝  ╚═══╝╚═════╝  ╚═════╝ ╚═╝  ╚═╝            ║`,
    `║                                                               ║`,
    `║ Stay safe, don't paste things into the console - Smartyr Team ║`,
    `╚═══════════════════════════════════════════════════════════════╝`,
  ];

  console.log(ascii.join("\n"));

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }));
    return forward(operation);
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
  });

  return (
    <ApolloProvider client={client}>
      <RootNavigator />
    </ApolloProvider>
  );
}

export default App;
