import * as yup from "yup";

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  EnvelopeIcon,
  PhoneIcon,
  UserIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";

import { CREATE_CONTACT } from "../../../../apollo/contact";
import { GET_CONTACTS } from "../../../../apollo/contacts";
import { phone } from "phone";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";

type FormData = {
  name: string;
  email: string;
  phone: string;
};

const validationSchema = yup.object().shape({
  name: yup.string().max(50, "Too Long!"),
  phone: yup
    .string()
    .test("isPhoneValid", "Invalid phone number", (value) =>
      value != undefined && value.length
        ? phone(value, { country: "AU" }).isValid
        : true
    ),
  email: yup.string().email("Invalid email"),
});

export default function ContactCreateForm() {
  const location = useLocation();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setError,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  function onSubmit(data: FormData) {
    if (isDirty) {
      createContact({
        variables: {
          contact: {
            name: data.name.length ? data.name : undefined,
            email: data.email.length ? data.email : undefined,
            phone: data.phone.length
              ? phone(data.phone, { country: "AU" }).phoneNumber
              : undefined,
          },
        },
      });
    }
  }
  const [createContact, { loading }] = useMutation(CREATE_CONTACT, {
    refetchQueries: [{ query: GET_CONTACTS }, "GetContacts"],
    onError: (error) => {
      error.graphQLErrors.forEach((error) => {
        switch (error.extensions.errors) {
          case "phone":
            setError("phone", {
              type: "server",
              message: error.message,
            });
            break;
          case "email":
            setError("email", {
              type: "server",
              message: error.message,
            });
            break;
          default:
            toast({
              title: "Error occured",
              description: error.message,
              status: "error",
            });
            console.log(error);
        }
      });
    },
    onCompleted: (data) => {
      reset();
      navigate(`/${location.pathname.split("/")[1]}/${data.createContact.id}`);
    },
  });

  return (
    <>
      <Tooltip placement="left" label="Create Contact" openDelay={200}>
        <IconButton
          aria-label="Create Contact"
          variant="ghost"
          onClick={onOpen}
          isLoading={loading}
        >
          <UserPlusIcon className="w-6 h-6 " />
        </IconButton>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create contact</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form
              className="space-y-4"
              id="create-contact"
              method="GET"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl isInvalid={!!errors.name}>
                <FormLabel>Name</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <UserIcon className="w-5 h-5" />
                  </InputLeftElement>
                  <Input
                    id="name"
                    type="text"
                    placeholder="i.e John Smith"
                    {...register("name")}
                  />
                </InputGroup>
                {!!errors.name && (
                  <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.email}>
                <FormLabel>Email</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <EnvelopeIcon className="w-5 h-5" />
                  </InputLeftElement>
                  <Input
                    id="email"
                    placeholder="i.e johnsmith@gmail.com"
                    type="text"
                    {...register("email")}
                  />
                </InputGroup>
                {!!errors.email && (
                  <FormErrorMessage>{errors.email.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!errors.phone}>
                <FormLabel>Phone</FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <PhoneIcon className="w-5 h-5" />
                  </InputLeftElement>
                  <Input
                    id="phone"
                    placeholder="i.e 0432 1234 214"
                    type="text"
                    {...register("phone")}
                  />
                </InputGroup>
                {!!errors.phone && (
                  <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
                )}
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button variant="solid" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              variant="solid"
              form="create-contact"
              isLoading={loading}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
