import {
  GET_CONTACT_INBOX_EVENTS,
  SEND_TEXT_MESSAGE,
} from "../../../../apollo/messaging";
import {
  attachmentStateVar,
  contactStatesVar,
  inputStatesVar,
  isMessageSendingVar,
  mediaUrlsVar,
} from "../../../../store/messaging";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";

import { Contact } from "../../../../types/Contact";
import { GET_USER_DETAILS } from "../../../../apollo/user";
import { useToast } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

export default function useSMS(
  contact: Contact | undefined,
  contactIds: Contact[] | undefined
) {
  const contactStates = useReactiveVar(contactStatesVar);
  const inputStates = useReactiveVar(inputStatesVar);
  const attachmentState = useReactiveVar(attachmentStateVar);
  const smsBodyRef = useRef(inputStates.SMSBody);
  const toast = useToast();
  const { data: userData } = useQuery(GET_USER_DETAILS);
  const [sendSMS, { data, loading, error }] = useMutation(SEND_TEXT_MESSAGE, {
    refetchQueries: () => {
      if (contact)
        return [
          {
            query: GET_CONTACT_INBOX_EVENTS,
            variables: {
              contactId: contact.id,
            },
          },
          "GetContactInboxEvents",
        ];
      return [];
    },
    onError(error) {
      console.log(error);
    },
    onCompleted(data) {
      attachmentStateVar(null);
      if (contact) {
        contactStatesVar({
          ...contactStates,
          [contact.id]: {
            ...contactStates[contact.id],
            SMSBody: "",
          },
        });
      }
      isMessageSendingVar(false);
    },
  });

  useEffect(() => {
    smsBodyRef.current = inputStates.SMSBody;
  }, [inputStates.SMSBody]);

  function getMessageObject() {
    if (attachmentState) {
      return {
        toNumber: contact!.phone,
        message: smsBodyRef.current,
        contactId: [contact!.id],
        fromNumber: userData.me.organisation.phones[0].phone,
        mediaUrls: [attachmentState],
      };
    }

    return {
      toNumber: contact!.phone,
      message: smsBodyRef.current,
      contactId: [contact!.id],
      fromNumber: userData.me.organisation.phones[0].phone,
    };
  }

  function handleSubmitSMS() {
    if (!smsBodyRef.current && !attachmentState) return;
    isMessageSendingVar(true);
    if (userData.me.organisation.phones.length) {
      sendSMS({
        variables: {
          message: getMessageObject(),
        },
        onError(error) {
          isMessageSendingVar(false);
          toast({
            title: "Error occured",
            description: error.message,
            status: "error",
          });
        },
      });
    } else {
      toast({
        title: "Error occurred.",
        description: "Please add a phone number to your organisation",
        status: "error",
      });
    }
  }

  return { handleSubmitSMS, loading, error, data };
}
